<template>
    <div>        
        <b-tabs v-model="tabIndex">
            <b-tab title="Detalles">
                 <b-row>
                    <b-col class="col-md-6" v-for="transfer in orderDetailTranfers" :key="transfer.id">
                        <NoteProduct :product="transfer" :typeItems="typeItems" />
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Itinerario">
                <b-row>
                    <b-col class="col-md-6" v-for="transfer in orderDetailTranfers" :key="transfer.id">
                        <TransferItinerary :product="transfer" :typeItems="typeItems" />
                    </b-col>
                </b-row>
            </b-tab>                    
        </b-tabs>      
        <!-- Control buttons-->
        <div class="text-center">
            <b-button-group class="mt-1">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="tabIndex--"
            >
                Anterior
            </b-button>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="tabIndex++"
            >
                Siguiente
            </b-button>
            </b-button-group>
        </div>         
    </div>       
</template>



<script>
import {  mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import NoteProduct from '@/modules/mySales/components/orderDetail/NoteProduct'
import TransferItinerary from '@/modules/mySales/components/orderDetail/TransferItinerary'

export default {
directives: {
    Ripple,
  },  
  components: {
    NoteProduct,
    TransferItinerary   
  },
  data() {
    return { 
      tabIndex: 0,   
      typeItems: 'transfers'    
    }
  }, 
  computed: {    
    ...mapGetters('mySales',['orderDetailTranfers'])                                       
  },   
  methods: {
    
  }
}
</script>

