<template>
    <div>
        <div v-if="typeItem == 'products' ">
            <a
                :href="couponLink + product.order + '/'+ product.productID +'/'+product.id +'/coupon/'"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-primary btn-block"
            > PDF
            </a>
        </div>
        <div v-if="typeItem == 'experience' ">
            <a
                :href="couponLink + product.order + '/'+ product.experience +'/'+product.id +'/coupon/'"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-primary btn-block"
            > PDF
            </a>
        </div>
        <div v-if="typeItem == 'transfers' ">
            <a
                :href="couponLink + product.order + '/' + product.productID + '/' + product.id + '/transfer/'"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-primary btn-block"
            > PDF
            </a>
        </div>
        <div v-if="typeItem == 'bookings' ">
            <a
                class="btn btn-primary btn-block"
                :href="couponLink + product.order + '/'+product.booking.id+'/'+product.id+'/booking/'"
                target="_blank"
                rel="noopener noreferrer"
            > Bono
            </a>
        </div>
        <div v-if="typeItem == 'packages' ">
            <a
                class="btn btn-primary btn-block"
                :href="couponLink + product.order + '/'+ product.package+'/' + product.id+'/package/'"
                target="_blank"
                rel="noopener noreferrer"
            > PDF
            </a>
        </div>
        <div v-if="typeItem == 'events' ">
            <a
                class="btn btn-primary btn-block"
                :href="couponLink + product.order + '/' + product.event+'/' + product.id +'/event/'"
                target="_blank"
                rel="noopener noreferrer"
            > PDF
            </a>
        </div>
    </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'

import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
    directives: {
        Ripple
    },
    props:{
        product:{
            type: Object,
            required: true
        },
        typeItem:{
            type: String,
            required: true
        }
    },
    data() {
        return {
            couponLink: process.env.VUE_APP_ORDER_DETAIL_COUPON_PDF,
            BEOLink: process.env.VUE_APP_ORDER_DETAIL_BEO_PDF,
        }
    },
}
</script>

