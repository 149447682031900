<template>
    <div>
        <a
            target="_blank"
            rel="noopener noreferrer"
            class="btn btn-primary btn-block"
            @click="resize"
        > Ticket
        </a>
    </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'

import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
    directives: {
        Ripple
    },
    props:{
        product:{
            type: Object,
            required: true
        },
        typeItem:{
            type: String,
            required: true
        }
    },   
    data() {
        return {
            TicketLink: process.env.VUE_APP_TICKET_HTML,
        }
    },
    methods:{
        resize(){
            if (this.product.paymentForm == 'Room Charge') {
                window.open(this.TicketLink + this.product.order +'/'+ this.product.id, "", "width=184, height=770").onafterprint = function(){window.close()}
            }
            window.open(this.TicketLink + this.product.order +'/'+ this.product.id, "", "width=184, height=584").onafterprint = function(){window.close()}
        }
    }
}
</script>