<template>
    <b-card class="border">
        <b-card-body>
            <b-card-title style="font-size:1em;" class="text-justify">                    
                <b-link class="card-link text-center" style="cursor:auto">
                    <div v-if="product.beneficiario.fullname.length > 0">
                       <h3>{{ product.beneficiario.fullname }}</h3> 
                    </div>
                    <div v-else>
                        <h3>{{ product.customer.name }}</h3>
                    </div>
                    <label>{{product.itemsold}}</label> 
                    <hr>
                </b-link>
            </b-card-title>
            <!-- <b-link class="card-link" style="cursor:auto" v-if="product.beneficiario.fullname.length > 0">
                <strong>Correo: </strong>{{ product.beneficiario.email }} <br> <strong>Teléfono: </strong> {{ product.beneficiario.phone }} <br>
                <strong>Habitación: </strong> {{ product.beneficiario.room }}
            </b-link> -->
            <ValidationObserver ref="observerBeneficiary" v-slot="{ invalid }">
                <b-row class="mt-1" v-if="product.beneficiario.fullname != null">
                    <b-col md="6">
                        <ValidationProvider rules="required" name="nombre">
                            <b-form-group label="Nombre" slot-scope="{ valid, errors }">
                            <b-form-input
                                :disabled="!enableActions"
                                class="form-control"
                                type="text"
                                v-model="product.beneficiario.fullname"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>   
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col md="6">
                        <ValidationProvider rules="required" name="email">
                            <b-form-group label="Email" slot-scope="{ valid, errors }">
                            <b-form-input
                                :disabled="!enableActions"
                                class="form-control"
                                type="email"
                                v-model="product.beneficiario.email"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>   
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col md="6">
                        <ValidationProvider rules="" name="phone">
                            <b-form-group label="Teléfono" slot-scope="{ valid, errors }">
                            <b-form-input
                                :disabled="!enableActions"
                                class="form-control"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                maxlength="20"
                                type="text"
                                v-model="product.beneficiario.phone"
                                :state="errors[0] ? false : (valid ? true : null)" 
                                @keypress="formatPhoneNumber" 
                            >
                            </b-form-input>   
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col md="6">
                        <ValidationProvider rules="" name="room">
                            <b-form-group label="Habitación" slot-scope="{ valid, errors }">
                            <b-form-input
                                :disabled="!enableActions"
                                class="form-control"
                                maxlength="10"
                                type="text"
                                v-model="product.beneficiario.room"
                                :state="errors[0] ? false : (valid ? true : null)" 
                            >
                            </b-form-input>   
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col>
                        <b-button
                            v-if="enableActions" class="btn-block" variant="primary"    
                            :disabled="invalid || isUpdatingBeneficiary"
                            @click="setBeneficiaryCart(product)"
                        > <b-spinner small v-if="isUpdatingBeneficiary"/> Actualizar
                        </b-button>
                    </b-col>
                </b-row>
            </ValidationObserver>

        </b-card-body>        
    </b-card>       
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    async mounted () {
        this.invalid = false;
    },
    props:{
        product:{
            type: Object,
            required: true
        },
    },    
    data() {
        return {                          
            isUpdatingBeneficiary: false,
            invalid: false,
        }
    },
    computed: {  
        ...mapState('auth',['user']),
        ...mapState('mySales',['orders']),
        enableActions() {
            const seller = this.product?.saleuser_id == this.user?.idUser
            const channel = this.product?.saleuserchannelid == this.user?.canalventa?.id

            const valid = (seller || channel) || false

            return valid
        },
    },   
    methods: {
        ...mapActions('mySales',['updateBeneficiary']),
        async setBeneficiaryCart(orderDetail){
            const { beneficiario } = orderDetail
            this.isUpdatingBeneficiary = true
            const payload = {
                data: {
                ...beneficiario
                }
            }
            // console.log(payload)
        await this.updateBeneficiary(payload)
        this.isUpdatingBeneficiary = false
        },
        formatPhoneNumber(event){
            let keyCode = (event.keyCode ? event.keyCode : event.which)
            //43 es +, 48-57(numeros del 0 al 9, 32 espacio, 45 es - )
            const codesAllowed = [ 32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
            const respuesta = codesAllowed.includes(keyCode)
			if (!respuesta) {                                       
				event.preventDefault()
			}
        },          
    }
}
</script>

