<template>
  <b-row>
    <b-col v-if="hasGuests.adults">
      <BookingGuests
        :guests="booking.guests.adults"
        @set-guests-in-properties="setDataInGuests"
      />
    </b-col>
    <b-col md="5" v-if="hasGuests.childs">
      <BookingGuests
        :guests="booking.guests.childs"
        @set-guests-in-properties="setDataInGuests"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from "vuex";
import { namesPrefix } from '@/data/data';
import BookingGuests from "@/modules/shop/components/orderSummary/BookingGuests"

export default {
  props: {
    booking: {
      type: Object,
      required: false,
    },
  },
  components: {
    BookingGuests,
  },
  data() {
    return {
      max: 255,
      namesPrefix,
      daGuest: '',
      guests: [],
      selectedGuest: [],
      childages: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17]
    };
  },
  computed: {
    ...mapState('mySales',['searchForm']),
    hasGuests(){
      const adults = this.booking?.guests?.adults && this.booking?.guests?.adults?.length > 0
      const childs = this.booking?.guests?.childs && this.booking?.guests?.childs?.length > 0
      return {adults, childs}
    }
  },
  methods: {
    setDataInGuests() {
      return true
    },
    cardTitle(guest){
      let number = 1
      if (guest.mainGuest) return `Main Guest: ${guest.GivenName} ${guest.Surname}`
      else return guest.Adult ? `Adult ${number++}: ${guest.GivenName} ${guest.Surname}` : `Child ${number++}: ${guest.GivenName} ${guest.Surname}`
    }
  },
};
</script>

<style scoped>
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #177bd2;
  background-color: #267dc7 !important;
}

label.custom-control-label {
  font-weight: 600;
}
</style>