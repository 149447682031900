<template>
  <div>
    <validation-observer ref="searchFormbooking" tag="form">
      <b-form>
        <b-row>
          <b-col md="3">
            <validation-provider name="Reserva">
              <b-form-group
                slot-scope="{ errors }"
                label="Reserva a actualizar*"
              >
                <b-form-select
                  :state="errors.length > 0 ? false : null"
                  v-model="searchForm.idRsv"
                  :disabled="isLoadingRooms"
                  @input="fillDataform"
                >
                  <option
                    v-for="(room, index) in listValidRsvs"
                    :key="index"
                    :value="room.booking.id"
                  >
                    {{ room.indice }}: {{ room.fullName }}
                  </option>
                  <option value="" v-if="listValidRsvs && listValidRsvs.length == 0" disabled>No hay reservas editables</option>
                </b-form-select>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <b-form-group label="Check in - Check out*">
              <flat-pickr
                v-model="searchForm.rangeDate"
                :disabled="isLoadingRooms"
                class="form-control"
                :config="{ mode: 'range', minDate: 'today' }"
                @input="setNights"
              />
            </b-form-group>
            <!-- <span>Noches: {{searchForm.nights}} </span>-->
          </b-col>
          <b-col md="2">
            <b-form-group label="Noches">
              <b-form-input v-model="searchForm.nights" disabled />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group>
              <validation-provider name="Adultos" rules="required">
                <b-form-group slot-scope="{ errors }" label="Adultos*">
                  <b-form-select
                    v-model="searchForm.adults"
                    :state="errors.length > 0 ? false : null"
                    :disabled="isLoadingRooms"
                    @input="setBookingsFilter(searchForm)"
                  >
                    <!-- @input="setDataForm"
									 -->
                    <option
                      v-for="(adulto, index) in adultOptions"
                      :key="index"
                      :value="adulto.value"
                    >
                      {{ adulto.title }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Niños*">
              <validation-provider
                #default="{ errors }"
                name="Niños"
                rules="required"
              >
                <b-form-select
                  v-model="searchForm.numberChildren"
                  :options="childrenOptions"
                  :state="errors.length > 0 ? false : null"
                  :disabled="isLoadingRooms"
                  @change="changeNumberChildren"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group label="Código promocional">
              <b-form-input
                v-model="searchForm.promoCodeManual"
                :disabled="isLoadingRooms"
                @input="setBookingsFilter(searchForm)"
              />
            </b-form-group>
          </b-col>
          <b-col md="2" v-for="(child, index) in searchForm.ninos" :key="index">
            <label :for="'AgeChild' + index">Edad niño {{ index + 1 }}*</label>
            <b-input-group>
              <b-form-select
                :id="'AgeChild' + index"
                v-model="child.edad"
                :disabled="isLoadingRooms"
                @change="setAgeNinio(child)"
              >
                <option
                  v-for="(edad, index) in ageChildrenOptions"
                  :key="index"
                  :value="edad.value"
                >
                  {{ edad.text }}
                </option>
              </b-form-select>
              <b-input-group-append>
                <b-button
                  variant="danger"
                  size="sm"
                  @click="deleteAgeChild(searchForm.ninos[index].key)"
                  :disabled="isLoadingRooms"
                >
                  <b-icon icon="trash-fill"></b-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="12" class="mt-1">
            <b-button
              variant="warning"
              @click.prevent="resetForm"
              :disabled="isLoadingRooms"
              >Limpiar</b-button
            >
            <!-- <div class="float-right">
              <b-button
                variant="primary"
                type="submit"
                :disabled="isLoadingRooms || searchForm.rsv == ''"
                @click.prevent="setBookingsFilter(searchForm)"
              >
                <b-spinner
                  small
                  label="Loading..."
                  variant="info"
                  v-if="isLoadingRooms"
                />
                Buscar
              </b-button>
            </div> -->
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import * as moment from "moment"
//data seeds
import { adultOptions, childrenOptions, ageChildrenOptions, searchBookingData,} from "@/data/data"
import {toJson, showAlertMessage, stringAleatorio, currentDate} from "@/helpers/helpers"
import flatPickr from "vue-flatpickr-component";

export default {
  async mounted() {
    this.roomsData.forEach((item, index) => {
      if (index == 0) this.searchForm.idRsv = item.booking.id
    });
  },
  props: {
    roomsData: {
      type: Array,
      required: true,
    },
    isLoadingRooms: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    flatPickr,
  },
  data() {
    return {
      adultOptions,
      childrenOptions,
      ageChildrenOptions,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("mySales", ["bookingSelected", "searchForm"]),
    listValidRsvs(item) {
      const bookings = [...this.roomsData];
      let valids = [];
      const statusBanned = ["Cancelled", "Annulled"]; // status no aceptados
      bookings.forEach((item, index) => {
        const isValidDate = moment(item.booking.dateIn).isAfter(currentDate()); //verifico si la fecha de ingreso, es mayor  la fecha actual
        //si status de item no está en los baneados y viene con dingusbooking
        if (!statusBanned.includes(item.orderdetailstatusname) && isValidDate) {
          item.fullName = `${item.booking.nameprefix} ${item.booking.givenname} ${item.booking.surname}`;
          item.indice = index + 1;
          valids.push(item);
        }
      });

      return valids;
    },
  },
  watch: {
    searchForm: {
      deep: true,
      handler() {
        const lengthRooms = this.bookingSelected ? this.bookingSelected.rooms.length : 0
        if (lengthRooms > 0) this.setRoomsBookingSelected([])
      },
    },
  },
  methods: {
    moment,
    ...mapActions("mySales", ["fetchDataBooking"]),
    ...mapMutations("mySales", [
      "setBookingSelected",
      "setBookingToEdit",
      "setRoomsBookingSelected",
      "setDataSearchForm",
      "clearDataSearchForm",
      "setAgeChildsSearchForm",
      "setNumberChildrenSearchForm",
      "setSpecificAgeChilSearchForm",
    ]),

    fillDataform(value) {
      if (value != "") {
        // id:orderDetail, alias como orderDetail
        const {
          booking,
          id: orderDetail,
          order,
          firstpay,
          partial,
        } = toJson(this.roomsData.find((item) => item.booking.id === value));
        booking.orderDetail = orderDetail;
        booking.order = order;
        booking.firstpay = firstpay;
        booking.partial = partial;
        this.setBookingSelected(booking); //muto bookingSelected
        this.setBookingToEdit(value); //muto bookingToEdit
        const {
          adults,
          hoteldinguscode,
          language,
          nights,
          children,
          ninios,
          promotioncode,
          rangeDate,
        } = booking;
        const vendorcode = this.user.canalventa.dingusvendorcode || "";
        const payloadForm = {
          adults,
          hoteldinguscode,
          language,
          nights,
          children,
          promotioncode,
          rangeDate,
          vendorcode,
          idRsv: value,
        };
        payloadForm.ninos = this.createArrayNinios(ninios);
        this.setDataSearchForm(payloadForm);
      }
      if (value == "") {
        this.setBookingToEdit(null); //muto bookingToEdit en nulo
        this.clearDataSearchForm();
      }
      this.setBookingsFilter(this.searchForm)
    },
    resetForm() {
      showAlertMessage(
        `Datos limpiados`,
        "BellIcon",
        `🎉🎉 Datos de formulario limpiados`,
        "success",
        3000,
        "bottom-right"
      );
      this.setBookingSelected(null);
      this.clearDataSearchForm();
    },

    setNights(rangeDate) {
      if (this.searchForm.rangeDate.includes("to")) {
        const splitDate = rangeDate.split(" to ")
        this.searchForm.dateIn = splitDate[0] || moment(new Date()).format("YYYY-MM-DD")
        this.searchForm.dateOut = splitDate[1] || moment(new Date()).add(1, "days").format("YYYY-MM-DD")
        const inicio = moment(splitDate[0])
        const fin = moment(splitDate[1])
        this.searchForm.nights = splitDate[0] && splitDate[1] ? fin.diff(inicio, "days") : 0
      } else this.searchForm.nights = 0
      this.setBookingsFilter(this.searchForm)
    },
    createArrayNinios(ninios) {
      let ninos = [];
      ninios.forEach((ageNinio) => {
        ninos.push({ edad: ageNinio, key: stringAleatorio() });
      });
      return ninos;
    },
    changeNumberChildren(numberChildren) {
      const { ninos } = toJson(this.searchForm);
      let ageNinos = [];
      const diferencia = numberChildren - ninos.length;

      if (diferencia == 0) ageNinos = ninos
      if (diferencia > 0) {
        for (let i = 0; i < diferencia; i++) {
          ageNinos.push({ edad: 1, key: stringAleatorio() });
        }
        ageNinos.unshift(...ninos); // recupero los datos originales y los añado a los nuevos
      }
      if (diferencia < 0) {
        for (let i = 0; i < numberChildren; i++) {
          ageNinos.push({ edad: 1, key: stringAleatorio() });
        }
      }
      this.setAgeChildsSearchForm(ageNinos)
      this.setBookingsFilter(this.searchForm)
    },
    setAgeNinio(child) {
      const { edad, key } = child
      this.setSpecificAgeChilSearchForm({ edad, key })
      this.setBookingsFilter(this.searchForm)
    },
    deleteAgeChild(key) {
      const ageNinios = this.searchForm.ninos.filter((nino) => nino.key != key)
      this.setAgeChildsSearchForm(ageNinios)
      this.setNumberChildrenSearchForm(this.searchForm.numberChildren - 1)
      this.setBookingsFilter(this.searchForm)
    },
    setBookingsFilter(searchForm) {
      const el = document.getElementById("footer")
      const { rangeDate, dateIn, dateOut, nights, rsv } = searchForm
      el.scrollIntoView({ behavior: "smooth" })
      this.$refs.searchFormbooking.validate().then((success) => {
        if (success) {
          if (rangeDate != 0 && dateIn != "" && dateOut != "" && nights > 0 && rsv != "") this.$emit("rooms-filter", searchForm)
          else showAlertMessage(
            `Fecha: Check in - Check out`,
            "InfoIcon",
            `😰😰 Falta que se indique valor para Check in - Check out`,
            "danger",
            3000,
            "bottom-right"
          );
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
