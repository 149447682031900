<template>
    <b-card class="border">
        <b-card-title style="font-size:1em" class="text-justify">
            <b-link style="cursor:auto">
                <strong>{{product.itemsold}} </strong>
            </b-link>
        </b-card-title>
        <b-tabs>
            <b-tab active>
                <template #title>
                    <span>🏡 Cabeceras</span>
                </template>
                <ValidationObserver ref="observerBEO1" v-slot="{ invalid }">
                    <b-row>
                        <b-col>
                            <span>Título</span>
                            <ValidationProvider rules="required" name="Título">
                                <b-input-group slot-scope="{ valid, errors }">
                                    <b-form-input placeholder="Nombre del SOS"
                                        :disabled="!enableActions"
                                        v-model="product.orderdetailbeo.titulo"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <span>Número de reserva</span>
                            <ValidationProvider rules="" name="Número reserva">
                                <b-input-group slot-scope="{ valid, errors }">
                                    <b-form-input placeholder="Ingrese la reserva"
                                        :disabled="!enableActions"
                                        v-model="product.orderdetailbeo.numreserva"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col>
                            <span>Locación</span>
                            <ValidationProvider rules="required" name="Locación">
                                <b-input-group slot-scope="{ valid, errors }">
                                    <b-form-input
                                        :disabled="!enableActions"
                                        placeholder="Ingrese locación"
                                        v-model="product.orderdetailbeo.locacion"
                                        :state="errors[0] ? false : (valid ? true : null)">
                                    </b-form-input>
                                    <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <span>Pax</span>
                            <ValidationProvider rules="" name="Pax">
                                <b-input-group slot-scope="{ valid, errors }">
                                    <b-form-input
                                        :disabled="!enableActions"
                                        type="number"
                                        placeholder="Ingrese numero de pax"
                                        v-model="product.orderdetailbeo.pax"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col>
                            <span>Comentario</span>
                            <ValidationProvider rules="max:255" name="comentario">
                                <b-form-group slot-scope="{ valid, errors }">
                                    <b-input-group>
                                        <b-form-textarea rows="2" max-rows="3" :maxlength="max"
                                            v-model="product.orderdetailbeo.comentario"
                                            :disabled="!enableActions"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-textarea>
                                        <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-input-group>
                                </b-form-group>
                            </ValidationProvider>
                            <span class="badge badge-success">
                                {{max - longitudComentario(product.orderdetailbeo.comentario) }} caracteres restantes para comentario
                            </span>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1" v-if="enableActions">
                        <b-col>
                            <div class="float-right">
                                <b-button
                                    variant="primary"
                                    :disabled="invalid || isUpdatingProductBEO"
                                    @click="updateBEO"
                                >
                                <b-spinner small v-if="isUpdatingProductBEO"/> Guardar
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </b-tab>
            <b-tab>
                <template #title>
                    <span> 📋 Detalles</span>
                </template>
                <ValidationObserver ref="observerBEO2" v-slot="{ invalid }" >
                    <b-row>
                        <b-col>
                            <span>Opciones</span>
                            <ValidationProvider name="departamento" rules="required">
                                <b-form-group slot-scope="{ valid, errors }" >
                                <b-form-select
                                    :disabled="!enableActions"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    v-model="selectedDpt"
                                >
                                    <option value="">Elija un departamento</option>
                                    <option v-for="field in departaments" :value="field"
                                    :key="field.value"
                                    >{{field.text}}
                                    </option>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <span>Valor</span>
                            <ValidationProvider rules="required" name="valor">
                                <b-form-group slot-scope="{ valid, errors }" >
                                    <b-form-input 
                                        type="text"
                                        :disabled="!enableActions"
                                        v-model="valorSelectDpt"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        placeholder="Ingrese el valor" 
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row class="text-center" v-if="enableActions ">
                        <b-col>
                            <span>Acciones</span><br>
                            <b-button variant="success" class="mr-1"
                                @click="createNewFieldsBEO"
                                :disabled="invalid"
                                size="sm"
                            >
                                <b-icon icon="plus-circle-fill"></b-icon> Añadir a lista
                            </b-button>
                            <b-button
                                variant="danger"
                                class="mr-1"
                                @click="clearInputsBEO"
                                :disabled="invalid"
                                size="sm"
                            >
                                <b-icon icon="x-circle-fill"></b-icon> Limpiar campos
                            </b-button>
                            <b-button
                                v-if="enableActions "
                                variant="primary"
                                size="sm"
                                :disabled="invalid || isUpdatingProductBEO"
                                @click="updateBEO"
                            >
                                <b-spinner small v-if="isUpdatingProductBEO"/> Guardar
                            </b-button>
                        </b-col>
                    </b-row>
                </ValidationObserver>

                <b-row class="mt-1">
                    <b-col>
                        <table v-if="longitudDetailBEO(product.orderdetailbeo.detailbeo) > 0"
                            class="table table-bordered table-sm"
                        >
                        <thead>
                            <tr>
                                <th scope="col">Campo</th>
                                <th scope="col">Valor</th>
                                <th v-if="enableActions" scope="col">Borrar</th>
                            </tr>
                        </thead>
                            <tbody>
                                <tr v-for="(tarea, index ) in product.orderdetailbeo.detailbeo" :key="index" >
                                    <td> {{tarea.departamentoname}} </td>
                                    <td> {{tarea.tarea}} </td>
                                    <td>
                                        <b-button v-if="enableActions" size="sm" variant="danger" @click="deleteFieldItemBEO(index)" >
                                            <b-icon icon="trash-fill"></b-icon>
                                        </b-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <b-alert variant="danger" show v-else>
                            <div class="alert-body"> 😣 No hay campos adicionales agregadas
                            </div>
                        </b-alert>
                    </b-col>
                </b-row>
            </b-tab>
        </b-tabs>
    </b-card>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { utils } from '@/modules/mySales/mixins/utils'
import { formatBeoProductToSave, toJson } from '@/helpers/helpers'

export default {
	mixins:[utils],
    props:{
        product:{
            type: Object,
            required: true
        },
    },
    components:{
    },
    data() {
        return {
            selectedDpt : null,
            valorSelectDpt : '',
            max: 255,
            isUpdatingProductBEO: false
        }
    },
    computed:{
        ...mapState('auth',['user']),
        ...mapState('mySales',['orders']),
        ...mapState('start',['departaments']),
        enableActions() {
            return this.isUserSellerOrSameChannel(this.product)
        },
    },
    methods: {
        ...mapActions('mySales',['updateBEOProduct']),
        ...mapMutations('mySales', ['setOrderDetail','setDetailBeoProduct','deleteDetailBeoProduct']),
        createNewFieldsBEO(){
            const payload = {  
                idProduct: this.product.id,
                infobeo: this.product.id,
                departamentoid: this.selectedDpt.value,
                departamentoname: this.selectedDpt.text,
                tarea: this.valorSelectDpt
            }
            this.setDetailBeoProduct( payload )
        },
        clearInputsBEO(){
            this.selectedDpt = null
            this.valorSelectDpt = ''
        },
        deleteFieldItemBEO(indice) {
            this.deleteDetailBeoProduct({idProduct: this.product.id, indice })
        },
        longitudComentario( comentario ){
            return comentario ? comentario.length : 0
        },
        longitudDetailBEO( detalleBEO ){
            return detalleBEO ? detalleBEO.length : 0
        },
        async updateBEO(){
            const item = toJson( this.product )
            const { orderdetailbeo } = item 
            const infobeo = orderdetailbeo.id
            orderdetailbeo.ordersdetail = item.id
            let detalleBEO = orderdetailbeo.detailbeo || []
            const detalles = formatBeoProductToSave(detalleBEO, item.id, infobeo)
            orderdetailbeo.detailbeo = detalles
            orderdetailbeo.idUser = this.user.idUser
            //actualizo
            this.isUpdatingProductBEO = true
            await this.updateBEOProduct( orderdetailbeo )
            this.isUpdatingProductBEO = false
        }
    }
}
</script>
