<template>
    <div>
        <b-tabs v-model="tabIndex">
            <b-tab title="Archivos Adjuntos">
                <TabFiles />
            </b-tab>
            <b-tab title="Histórico de Movimientos"> <!-- v-if="can('my_sales_menu_show_my_sales_view_history_log_tab')" -->
                <TabLogs v-if="tabIndex == 1" />
            </b-tab>
            <b-tab title="Autorizaciónes Pricematch" v-if="HasAuthFiles">
                <TabPriceMatch />
            </b-tab>
        </b-tabs>

        <!-- Control buttons-->
        <div class="text-center">
            <b-button-group class="mt-1">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="tabIndex--"
                >
                    Anterior
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="tabIndex++"
                >
                    Siguiente
                </b-button>
            </b-button-group>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from "vuex"
import TabFiles from '@/modules/mySales/components/orderDetail/TabFiles'
import TabPriceMatch from '@/modules/mySales/components/orderDetail/TabPriceMatch'
import TabLogs from '@/modules/mySales/components/orderDetail/TabLogs'
import Ripple from 'vue-ripple-directive'
import { acl } from "@/modules/auth/mixins/acl"

export default {
    mixins: [acl],
    components: {
        TabFiles,
        TabPriceMatch,
        TabLogs
    },
    directives: {
        Ripple
    },
    data() {
        return {
            tabIndex: 0,
            typeItems: 'products'
        }
    },
    computed:{
        ...mapState('mySales', ['selectedOrder']),
        ...mapGetters('mySales',['orderDetailBookings']),
        HasAuthFiles(){
            const hasauthfiles = this.orderDetailBookings.filter(detail => detail.booking?.urlauths?.length > 0)
            return hasauthfiles?.length > 0 ? true : false
        },
    },
}
</script>