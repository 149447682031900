<template>
    <b-row>
      <b-col class="col-md-12">
        <b-card>
          <b-card-body>
            <b-row class="mt-2">
              <b-col :md="colSize" v-for="details in selectedOrder.detail" :key="details.id">
                <div class="text-center">
                  <h4>{{details.booking.roomtypename}}</h4>
                  <h6>{{details.booking.roomtypecode}} - {{details.booking.rateplanname}} - {{details.booking.mealplancode}} - {{details.booking.hoteldinguscode}}</h6>
                  <hr>
                  <span>
                    Promocode: <strong>{{details.booking.promotioncode}}</strong>.<br/>
                    Autorizado por: <strong>{{details.booking.authorizationUser}}</strong>
                    el día: <strong>{{details.booking.dateAuthorization}}</strong>
                  </span>
                  <br>
                  <br>
                </div>
                <b-table
                  :items="details.booking.urlauths"
                  :fields="fields"
                  responsive
                  small
                  show-empty
                  empty-text="Esta orden no cuenta con archivos"
                  sticky-header="400px"
                >
                  <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle" label="Loading..." variant="success"></b-spinner>
                      <strong> Cargando...</strong>
                    </div>
                  </template>
                  <template #cell(filename)="row">
                    <label>
                      {{row.item.filename}} - {{replacedTypeNames(row.item.filetype)}}
                    </label>
                  </template>
                  <template #cell(uploadDate)="row">
                    <label>
                      {{row.item.uploadDate}} <br>
                      por: {{row.item.uploaderName}} {{row.item.uploaderLastname}} 
                    </label>
                  </template>
                  <template #cell(actions)="row">
                  <b-button
                    size="sm"
                    class="mr-1 mb-1"
                    type="submit"
                    variant="primary"
                    v-b-tooltip.hover
                    title="'Ver Archivo'"
                    :href="fileURL + '/' + row.item.urlfile"
                    target="_blank"
                    rel="noopener noreferrer">
                    <feather-icon
                      icon="DownloadIcon"
                      size="14"
                    />
                  </b-button>
                </template>
                  <template #table-caption>Máx. 5 archivos de 2Mb c/u.</template>
                </b-table>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </template>
  
<script>
import { mapState } from "vuex"
  
export default {
  async mounted() {
    this.tableInfo()
  },
  data(){
    return {
      file: null,
      orderFiles: null,
      tableData: null,
      orderId: null,
      fileURL: process.env.VUE_APP_IMG_SRC_API,
      fields: [
        {key: 'filename' , label: 'Archivo'},
        {key: 'uploadDate' , label: 'Alta'},
        {key: 'actions', label: 'Acciones', class: 'text-center'}
      ],
    }
  },
  computed:{
    ...mapState('mySales', ['selectedOrder']),
    colSize(){
      const hasauthfiles = this.selectedOrder.detail.filter(detail => detail.booking?.urlauths?.length > 0)
      return hasauthfiles?.length > 1 ? '6' : '12'
    },
  },
  methods: {
    tableInfo(){
      let data = []
      this.selectedOrder?.detail?.forEach(detail => {
        detail.booking?.urlauths?.forEach(urls => {
          data.push({
            urlfile: urls?.urlfile || '',
            filename: urls?.filename || '',
            filetype: urls?.filetype || '',
            uploaduser: urls?.uploaduser || '',
            uploadDate: urls?.uploadDate || ''
          })
        })
      })
      this.tableData = data
    },
    replacedTypeNames(data){
      if (data == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') return 'Documento Word'
      if (data == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') return 'Documento Excel'
      if (data == 'application/vnd.openxmlformats-officedocument.presentationml.presentation') return 'Presentación de PowerPoint'
      return data
    }
  }
}
</script>
  