<template>
  <div>
    <b-card-title style="font-size:1em" class="text-justify">                    
      <b-link class="card-link" style="cursor:auto">
        <h4>{{product.itemsold}} </h4>        
      </b-link>
    </b-card-title>
    
    <p><strong>Name:</strong> {{ product.booking.nameprefix }} {{ product.booking.givenname }} {{ product.booking.surname }} || <strong>Email:</strong> {{ product.booking.email }} || <strong>Phone:</strong> {{ product.booking.phonenumber }}</p>
    <b-badge  class="mr-1 mb-1" pill variant="primary">Orden: {{ product.order }}</b-badge>
    <b-badge  class="mr-1 mb-1" pill variant="primary">Total: ${{ product.booking.total }}</b-badge>
    <b-badge  class="mr-1 mb-1" pill variant="success" v-if="product.booking.dingusbooking">Reserva: {{product.booking.dingusbooking}}</b-badge>
    
    <b-badge  class="mr-1 mb-1" pill variant="primary">Hotel: {{ product.booking.hotelname }}</b-badge>
    <b-badge  class="mr-1 mb-1" pill variant="primary">Habitación: {{product.booking.roomtypename }}</b-badge>
    <b-badge  class="mr-1 mb-1" pill variant="primary">Adultos: {{ product.booking.previousAdults }}</b-badge>
    <b-badge  class="mr-1 mb-1" pill variant="primary" v-if="product.booking.children > 0">Niños: {{ product.booking.children }}</b-badge>
    <b-badge  class="mr-1 mb-1" pill variant="primary">Check in: {{ product.booking.previousDateIn }}</b-badge>
    <b-badge  class="mr-1 mb-1" pill variant="primary">Check out: {{ product.booking.previousDateOut }}</b-badge>
    <b-badge  class="mr-1 mb-1" pill variant="primary">Noches: {{ product.booking.previousNights }}</b-badge>
    <b-badge  class="mr-1 mb-1" pill variant="primary">Meal Plan: {{ product.booking.mealplancode}}</b-badge>
    <b-badge  class="mr-1 mb-1" pill variant="primary">Rate Plan: {{ product.booking.rateplancode }}</b-badge>                  
    <b-badge  class="mr-1 mb-1" pill variant="success" v-if="product.booking.dingusbooking && product.orderdetailstatusname !='Cancelled'">Status: confirmado</b-badge>
    <b-badge  class="mr-1 mb-1" pill variant="danger" v-if="product.orderdetailstatusname =='Cancelled'">Status: Cancelado</b-badge>
    <b-badge  class="mr-1 mb-1" pill variant="warning" v-if="!product.booking.dingusbooking">Status: No confirmado</b-badge>
    <b-badge  class="mr-1 mb-1" pill variant="info" v-if=" product.booking.promotioncode!=''">Promoción: {{ product.booking.promotioncode }}</b-badge>     
  </div>      
</template>

<script>
import { mapActions, mapGetters,  mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import { currentDate } from '@/helpers/helpers' 
import HeaderBooking from '@/modules/mySales/components/orderDetail/HeaderBooking'

import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
    directives: {
        Ripple
    },
    props:{
        product:{
            type: Object,
            required: true
        },
    },
    components: {
        VueTimepicker,
        HeaderBooking
    },
    data() {
        return {              
            max: 255,        
            isOpenModalDateOperation: false,                        
            couponLink: process.env.VUE_APP_ORDER_DETAIL_COUPON_PDF,        
            BEOLink: process.env.VUE_APP_ORDER_DETAIL_BEO_PDF,        
            fechaActual: currentDate()    
        }
    }, 
    computed: {  
        ...mapState('auth',['user']),
        ...mapGetters('mySales',['orderproductProducts'])                                   
    },   
    methods: {
        ...mapActions('mySales',['updateOperationDateTimeNote']),         
        async setOperationDateTimeNote(orderdetail, operationdate, operationtime, note, clientnote ){
            const payload = { orderdetail, operationdate, operationtime, note, idUser: this.user.idUser, clientnote }
            // console.log(payload)     
            await this.updateOperationDateTimeNote(payload)        
        }
    }
}
</script>

