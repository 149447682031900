<template>
  <b-row>
    <b-col class="col-md-12">
      <b-card>
        <b-card-body>
          <b-row v-if="enableActions">
            <b-col md="12"><h5>Añadir Archivos</h5></b-col>
            <b-col>
              <label for="">Añadir nuevo archivo</label>
              <b-form-file
                v-model="file"
                multiple
                placeholder="Escoge un archivo o sueltalo aquí..."
                drop-placeholder="Suelta el archivo aquí..."
                browse-text="Buscar archivo"
              />
              <label for="Máx. 5 archivos de 2Mb c/u"></label>
            </b-col>
            <b-col>
              <validation-provider name="notas" rules="required">
                <b-form-group slot-scope="{ valid, errors }" label="Añadir notas al archivo" >
                  <b-form-textarea
                    v-model="notes"
                    :state="errors[0] ? false : valid ? true : null"
                  />
                  <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="6">
              <h5>Archivos Activos</h5>
              <b-table
                :items="activeOrders"
                :fields="fields"
                responsive
                small
                show-empty
                empty-text="Esta orden no cuenta con archivos"
                :busy.sync="isloadingFiles"
                sticky-header="400px"
              >
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle" label="Loading..." variant="success"></b-spinner>
                    <strong> Cargando...</strong>
                  </div>
                </template>
                <template #cell(filename)="row">
                  <label>
                    {{row.item.filename}} - {{replacedTypeNames(row.item.filetype)}}
                  </label>
                  <b-spinner v-if="isdeletingFiles" small class="align-middle" label="Loading..." variant="success"></b-spinner>
                </template>
                <template #cell(uploadDate)="row">
                  <label>
                    {{row.item.uploadDate}} <br>
                    por: {{row.item.uploaduser[0]}} 
                  </label>
                </template>
                <template #cell(actions)="row">
                  <b-button
                    size="sm"
                    class="mr-1 mb-1"
                    type="submit"
                    variant="primary"
                    v-b-tooltip.hover
                    title="'Ver Archivo'"
                    :href="fileURL + '/' + row.item.urlfile"
                    target="_blank"
                    rel="noopener noreferrer">
                    <feather-icon
                      icon="DownloadIcon"
                      size="14"
                    />
                  </b-button>
                  <b-button
                    v-if="enableActions"
                    size="sm"
                    class="mr-1 mb-1" variant="danger"
                    v-b-tooltip.hover.top="'Eliminar Archivo'"
                    @click="deleteFile(row.item)"
                    :disabled="isdeletingFiles"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      size="14"
                    />
                  </b-button>
                </template>
                <template #cell(notes)="data">
                  <b-button v-b-popover.hover.top="data.value" title="Notas" size="sm" variant="primary" class="mr-1 mb-1" :disabled="isdeletingFiles">
                    <feather-icon
                      icon="InfoIcon"
                      size="14"
                    /></b-button>
                </template>
                <template #table-caption>Máx. 5 archivos de 2Mb c/u.</template>
              </b-table>
            </b-col>
            <b-col md="6" class="border-left">
              <h5>Archivos Eliminados</h5>
              <b-table
                :items="inactiveOrders"
                :fields="deleteFields"
                responsive
                small
                show-empty
                empty-text="Esta orden no cuenta con archivos eliminados"
                :busy.sync="isloadingFiles"
                sticky-header="400px"
              >
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle" label="Loading..." variant="success"></b-spinner>
                    <strong> Cargando...</strong>
                  </div>
                </template>
                <template #cell(filename)="row">
                  <label>
                    {{row.item.filename}} -
                    {{replacedTypeNames(row.item.filetype)}}
                  </label>
                </template>
                <template #cell(deleteDate)="row">
                  <label>
                    {{row.item.deleteDate}} <br>
                    por: {{row.item.deleteuser[0]}}
                  </label>
                </template>
                <template #cell(uploadDate)="row">
                  <label>
                    {{row.item.uploadDate}} <br>
                    por: {{row.item.uploaduser[0]}} 
                  </label>
                </template>
                <template #cell(notes)="data">
                  <b-button v-b-popover.hover.top="data.value" title="Notas" size="sm" variant="primary">
                    <feather-icon
                      icon="InfoIcon"
                      size="14"
                    /></b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <div class="float-right" v-if="enableActions">
            <b-button
              type="submit"
              class="btn-block"
              variant="primary"
              @click="SaveFile()"
              :disabled="updateFiles"
            >
              <b-spinner small v-if="isSavingFiles" /> Guardar
            </b-button>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapState, mapActions } from "vuex"
import {  showAlertMessage, sweetAlert, filterFilesByType, filterFilesBySize, filterFilesByName } from '@/helpers/helpers'

export default {
  async created() {
    this.orderUrl = this.$route.params.order     
    const orderId = await this.setOrderFromUrl( this.orderUrl  )
    await this.loadInitialDocs(orderId)
  },
  data(){
    return {
      isSavingFiles: false,
      isloadingFiles: false,
      isdeletingFiles: false,
      file: null,
      orderFiles: null,
      activeOrders: null,
      inactiveOrders: null,
      notes: '',
      orderId: null,
      fileURL: process.env.VUE_APP_IMG_SRC_API,
      fields: [
        {key: 'filename' , label: 'Archivo'},
        {key: 'uploadDate' , label: 'Alta'},
        {key: 'notes' , label: 'Notas', class: 'text-center'},
        {key: 'actions', label: 'Acciones', class: 'text-center'}
      ],
      deleteFields: [
        {key: 'filename' , label: 'Archivo'},
        {key: 'uploadDate' , label: 'Alta'},
        {key: 'deleteDate' , label: 'Baja'},
        {key: 'notes' , label: 'Notas', class: 'text-center'},
      ]
    }
  },
  computed:{
    ...mapState('auth',['user']),
    ...mapState('mySales', ['selectedOrder']),
    updateFiles(){
      if (this.activeOrders?.length > 4 || this.isSavingFiles || this.isloadingFiles) return true
    },
    enableActions(){
      const user = (this.selectedOrder?.saleuser_id === this.user?.idUser) || false
      const channel = (this.selectedOrder?.saleuserchannelid === this.user?.canalventa?.id ) || false

      const valid = (user === true || channel === true) || false

      return valid
    }
  },
  methods: {
    ...mapActions('mySales', ['uploadFileFromOrder', 'getFileFromOrder', 'deleteFileFromOrder']),
    setOrderFromUrl( cadena ){    
      const arreglo = cadena.split("-", 2)
      const idOrder = parseInt(arreglo[1]) 
      const orderIsANumber = !isNaN(idOrder)
      if( orderIsANumber ){
        this.orderId = idOrder
        return idOrder}
    },
    async loadInitialDocs(id){
      this.isloadingFiles = true
      const response = await this.getFileFromOrder(this.orderId || id )
      this.orderFiles = response.data

      let active = []
      let inactive = []
      this.orderFiles.forEach(files => {
        if (files.status) active.push(files)
        else inactive.push(files)
      })
      this.activeOrders = active
      this.inactiveOrders = inactive
      this.isloadingFiles = false
    },
    async deleteFile(item){
      const swalert = {
        title: 'Eliminando documento: ' + item.filename,
        message: '¿Desea eliminar este documento?',
        icon: 'warning',
        confirmButton: 'Eliminar',
        cancelButton: 'Cancelar',
      }

      const {isConfirmed} = await sweetAlert(swalert)

      if (isConfirmed){
        this.isdeletingFiles = true
        const payload = {
          file: item.id,
          deleteUser: this.user.idUser,
          idOrder: this.orderId
        }
        const response = await this.deleteFileFromOrder(payload)
        if (response) this.loadInitialDocs(this.orderId)
        this.isdeletingFiles = false
      } else {
        this.isdeletingFiles = false
        return showAlertMessage( 'Se ha cancelado la operación', 'InfoIcon', 'No se ha borrado el archivo', 'warning', 4000, 'bottom-right')
      }
    },
    async SaveFile(){
      if (!this.file || this.file.length == 0) return showAlertMessage( 'Sin archivos', 'InfoIcon', 'Por favor, elija un archivo', 'warning', 4000, 'bottom-right')

      if (this.activeOrders.length > 5 || (this.file.length + this.activeOrders.length) > 5) {
        this.file = null
        return showAlertMessage( 'Maximo alcanzado', 'InfoIcon', 'No puedes subir más de 5 archivos', 'warning', 4000, 'bottom-right')
      }

      const filteredByType = filterFilesByType(this.file)
      if (!filteredByType) return

      const filteredBySize = filterFilesBySize(filteredByType, 2202009.6)
      if (!filteredBySize) return

      const filteredByName = filterFilesByName(filteredBySize)
      if (!filteredByName) return

      const payload = {
        file: this.file,
        uploadUser: this.user.idUser,
        notes: this.notes,
        idOrder: this.orderId
      }
      try {
        this.isSavingFiles = true
        const response = await this.uploadFileFromOrder(payload)
        if (response) {
          this.loadInitialDocs(this.orderId)
          this.file = null
          this.notes = ''
        }
        this.isSavingFiles = false
      } catch (error) {
        this.isSavingFiles = false
      }
      return
    },
    replacedTypeNames(data){
      if (data == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') return 'Documento Word'
      if (data == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') return 'Documento Excel'
      if (data == 'application/vnd.openxmlformats-officedocument.presentationml.presentation') return 'Presentación de PowerPoint'
      return data
    }
  }
}
</script>
