<template>
    <b-card class="border">
        <b-card-body>
            <b-card-title style="font-size:1em" class="text-justify">
                <b-link class="card-link text-center" style="cursor:auto">
                    <h3>{{product.booking.roomtypename}} </h3>
                    <h5>{{product.booking.roomtypecode}} - {{product.booking.rateplanname}} - {{product.booking.mealplancode}}</h5>
                    <hr>
                </b-link>
            </b-card-title>
            <b-link class="card-link" style="cursor:auto" >
                <b-row>
                    <b-col md="6" class="text-center">
                        <strong>Orden: </strong>  {{ product.order }}  <br>
                        <span v-if="product.booking.dingusbooking"><strong>Reserva: </strong> {{ product.booking.dingusbooking }}<br></span>
                        <strong>Hotel: </strong> {{ product.booking.hotelname }} <br>
                        <strong>Habitación: </strong> {{ product.booking.roomtypename }}
                    </b-col>

                    <b-col md="6" class="text-center" :class="windowWidth > 767 ? 'd-none' : '' ">
                        ...<br><strong>Adultos: </strong> {{ product.booking.adults }} <br>
                        <strong>Check in:</strong> {{ product.booking.dateIn }} <br>
                        <strong>Check Out:</strong> {{ product.booking.dateOut }} <br>
                        <strong>Total: </strong> {{ setCurrency(product.booking.total) }}
                    </b-col>
                    <b-col md="6" class="text-center" style="border-left-style:solid;border-left-width:thin;" :class="windowWidth < 768 ? 'd-none' : '' ">
                        <strong>Adultos: </strong> {{ product.booking.adults }} <br>
                        <strong>Check in:</strong> {{ product.booking.dateIn }} <br>
                        <strong>Check Out:</strong> {{ product.booking.dateOut }} <br>
                        <strong>Total: </strong> {{ setCurrency(product.booking.total) }}
                    </b-col>
                </b-row>
            </b-link>
            <span class="badge badge-success mt-1" v-if="product.confirmationdate">
                Confirmed: {{ product.confirmationdate }}
            </span>
            <b-row class="mt-1">
                <b-col md="6">
                    <b-form-group label="Nota interna">
                        <b-form-textarea v-model="product.note" placeholder="Escribe la nota de uso interno" :maxlength="max" rows="2" >
                        </b-form-textarea>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Nota cliente">
                        <b-form-textarea  v-model="product.clientnote" placeholder="Escribe la nota para el cliente" :maxlength="max" rows="2" >
                        </b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col style="padding-bottom:1rem;">
                    <a
                        class="btn btn-primary btn-block"
                        :href="couponLink+product.order+'/'+product.booking.id+'/'+product.id+'/booking/'"
                        target="_blank"
                        rel="noopener noreferrer"
                    > Bono
                    </a>
                </b-col>
                <b-col style="padding-bottom:1rem;">
                    <div v-if=" product.orderdetailbeo.id != '' ">
                        <div v-if="product.orderdetailStatus == 1 ||
                            product.orderdetailStatus == 3 ||
                            product.orderdetailStatus == 4 ||
                            product.orderdetailStatus == 7">
                        </div>
                        <a
                            class="btn btn-primary btn-block"
                            :href="BEOLink + product.order +'/'+ product.id"
                            target="_blank"
                            rel="noopener noreferrer"
                        > SOS
                        </a>
                    </div>
                </b-col>
                <b-col>
                    <b-button
                  
                    class="btn-block" variant="primary"
                    @click="setOperationDateTimeNote(product.id, product.operationdate, product.operationtime, product.note, product.clientnote)"
                    > Guardar
                    </b-button>
                </b-col>
            </b-row>
            <b-modal
                :id="'modal-date-oparation' + product.id"
                ok-only no-close-on-backdrop size="md" ok-title="Ok" centered :title="product.itemsold" 
            >
                <DateOperation :product="product"/>
            </b-modal>
        </b-card-body>
    </b-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import { currentDate, toCurrency } from '@/helpers/helpers'
import DateOperation from '@/modules/mySales/components/orderDetail/DateOperation'

import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
    directives: {
        Ripple
    },
    props:{
        product:{
            type: Object,
            required: true
        },
    },
    components: {
        VueTimepicker,
        DateOperation
    },
    data() {
        return {
            max: 255,
            isOpenModalDateOperation: false,
            couponLink: process.env.VUE_APP_ORDER_DETAIL_COUPON_PDF,
            BEOLink: process.env.VUE_APP_ORDER_DETAIL_BEO_PDF,
            fechaActual: currentDate()
        }
    }, 
    computed: {
        ...mapState('auth',['user']),
        ...mapState('appConfig',['windowWidth']),
        ...mapGetters('mySales',['orderproductProducts']),
        ...mapState('mySales',['selectedOrder']),
        ...mapState('start',['currencies']),
        enableActions() {
            const seller = this.product?.saleuser_id == this.user?.idUser
            const channel = this.product?.saleuserchannelid == this.user?.canalventa?.id

            const valid = (seller || channel) || false

            return valid
        },
    },   
    methods: {
        ...mapActions('mySales',['updateOperationDateTimeNote']),
        async setOperationDateTimeNote(orderdetail, operationdate, operationtime, note, clientnote ){
            const payload = { orderdetail, operationdate, operationtime, note, idUser: this.user.idUser, clientnote }
            // console.log(payload)
            await this.updateOperationDateTimeNote(payload)
        },
        setCurrency(data){
            const actualCurrency = this.currencies?.find((money)=> money?.currencyid == this.selectedOrder?.currency.id) 
            return toCurrency(parseFloat(data) * parseFloat(actualCurrency?.value), actualCurrency?.code) +' '+ actualCurrency?.code
        }
    }
}
</script>

