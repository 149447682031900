<template>
    <div>
        <b-tabs v-model="tabIndex">
            <b-tab title="Detalles">
                <b-row>
                    <b-col class="col-md-6" v-for="product in orderDetailExperiences" :key="product.id">
                        <NoteExperience :product="product" :typeItems="typeItems"/>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Beneficiario">
                <b-row>
                    <b-col class="col-md-6" v-for="product in orderDetailExperiences" :key="product.id">
                        <TabBeneficiary :product="product" :typeItems="typeItems"/>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="SOS" v-if="orderHasProductsWithBEO">
                <b-row>
                    <b-col class="col-md-6" v-for="product in productsWithBeo" :key="product.id">
                        <BEOProduct :product="product"/>
                    </b-col>
                </b-row>
            </b-tab>
        </b-tabs>
        <!-- Control buttons-->
        <div class="text-center">
            <b-button-group class="mt-1">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="tabIndex--"
                >
                    Anterior
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="tabIndex++"
                >
                    Siguiente
                </b-button>
            </b-button-group>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import NoteExperience from '@/modules/mySales/components/orderDetail/NoteExperience'
import BEOProduct from '@/modules/mySales/components/orderDetail/BEOProduct'
import TabBeneficiary from '@/modules/mySales/components/orderDetail/TabBeneficiary'

export default {
directives: {
    Ripple,
  },
  components: {
    NoteExperience,
    BEOProduct,
    TabBeneficiary
  },
  data() {
    return {
      tabIndex: 0,
      typeItems: 'experience',
      productsWithBeo: []
    }
  },
  computed: {    
    ...mapGetters('mySales',['orderDetailExperiences']),
    orderHasProductsWithBEO(){
        const productsWithBeo = this.orderDetailExperiences.filter( product => Boolean(product?.orderdetailbeo?.id) )
        this.productsWithBeo = structuredClone(productsWithBeo)
        return productsWithBeo.length > 0
    }
  },
  methods: {
    
  }
}
</script>

