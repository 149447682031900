<template>
    <div>
        <b-tabs v-model="tabIndex">
            <b-tab title="Detalles">
                <b-row>
                    <b-col class="col-md-6" v-for="booking in orderDetailBookings" :key="booking.id">
                        <NoteBooking :product="booking"/>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Huéspedes">
                <b-row>
                    <b-col class="col-md-6" v-for="booking in orderDetailBookings" :key="booking.id">
                        <TitularBooking :product="booking"/>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Actualizar">
                <div v-if="!aBookingIsUpdating">
                    <TableRoomBookings :bookingListUpdate="bookingListUpdate" />
                    <div v-if="showDataTitular && listValidRsvs > 0">
                        <hr>
                        <form-wizard
                            color="#333366"
                            title="Edición de reserva"
                            :subtitle="null"
                            layout="horizontal"
                            finish-button-text="Buscar"
                            back-button-text="volver"
                            next-button-text="siguente"
                            class="steps-transparent mb-3"
                            @on-complete="searchRooms"
                            >

                            <!-- account details tab -->
                            <tab-content title="Busqueda de Reserva" icon="feather icon-search" :before-change="formatNewBooking">
                                <SearchForm :roomsData="orderDetailBookings" :isLoadingRooms="isLoadingRooms" @rooms-filter="setFilterForRooms"/>
                            </tab-content>

                            <!-- account details tab -->
                            <tab-content title="Edición de los Huéspedes" icon="feather icon-users">
                                <b-card-actions
                                    actionCollapse
                                    title="Huéspedes"
                                >
                                <GuestForm :booking="bookingSelected" />
                            </b-card-actions>
                            </tab-content>

                        </form-wizard>

                        <!-- <SearchForm :roomsData="orderDetailBookings" :isLoadingRooms="isLoadingRooms" @search-rooms="searchRooms"/> -->
                        <hr>
                        <RoomsList :isLoadingRooms="isLoadingRooms"  @update-booking="updateMyBooking" />
                    </div>
                    <div v-else>
                        <b-alert show variant="warning" class="p-1 text-center">
                            No hay reservas válidas para editar
                        </b-alert>
                    </div>
                </div>
                <div class="text-center" v-if="aBookingIsUpdating">
                    <b-spinner label="Loading..." variant="success"/><br>
                    <strong>Actualizando reserva</strong>
                </div>
            </b-tab>
        </b-tabs>
        <!-- Control buttons-->
        <div class="text-center">
            <b-button-group class="mt-1">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="tabIndex--"
            >
                Anterior
            </b-button>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="tabIndex++"
            >
                Siguiente
            </b-button>
            </b-button-group>
        </div>
    </div>
</template>

<script>
import {  mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import Ripple from 'vue-ripple-directive'
import NoteBooking from '@/modules/mySales/components/orderDetail/NoteBooking'
import TitularBooking from '@/modules/mySales/components/orderDetail/TitularBooking'
import HeaderBooking from '@/modules/mySales/components/orderDetail/HeaderBooking'

import TableRoomBookings from '@/modules/mySales/components/orderDetail/TableRoomBookings'
import SearchForm from '@/modules/mySales/components/orderDetail/SearchForm'
import RoomsList from '@/modules/mySales/components/orderDetail/RoomsList'
import GuestForm from '@/modules/mySales/components/orderDetail/GuestForm'
import { toJson, showAlertMessage, currentDate } from '@/helpers/helpers'
import { formatGuestsInBooking } from '@/helpers/shopHelper'
import { formatGuestsInMySalesBooking } from "@/helpers/mySalesHelper"
import * as moment from "moment"
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
    directives: {
        Ripple
    },
    components: {
        NoteBooking,
        TitularBooking,
        HeaderBooking,
        TableRoomBookings,
        SearchForm,
        RoomsList,
        GuestForm,
        BCardActions
    },
    data() {
        return {
            tabIndex: 0,
            typeItems: 'bookings',
            isLoadingRooms: false,
            validaStep: false,
            editbooking: null
        }
    },
    computed: {
        ...mapGetters('mySales',['orderDetailBookings']),
        ...mapState('auth',['user']),
        ...mapState('mySales',['orderDetail','aBookingIsUpdating','bookingToEdit','bookingSelected', 'searchForm', 'selectedOrder']),

        listValidRsvs() {
            const bookings = [...this.orderDetailBookings];
            let valids = [];
            const statusBanned = ["Cancelled", "Annulled"]; // status no aceptados
            bookings.forEach( item => {
                const isValidDate = moment(item.booking.dateIn).isAfter(currentDate()) //verifico si la fecha de ingreso, es mayor la fecha actual
                //si status de item no está en los baneados y viene con dingusbooking
                if (!statusBanned.includes(item.orderdetailstatusname) && isValidDate) valids.push(item.id)
            })
            return valids?.length
        },
        showDataTitular(){
            const orderDetail = this.orderDetailBookings ? this.orderDetailBookings[0] : null
            if( orderDetail ){
                const seller = orderDetail?.saleuser_id == this.user?.idUser
                const channel = orderDetail?.saleuserchannelid == this.user?.canalventa?.id
                const owner = this.selectedOrder?.customer?.typeCustomer == 2

                const valid = ((seller || channel) && !owner) || false

                return valid
            }
            return false
        },
        bookingListUpdate(){
            if( !this.bookingToEdit ) return this.orderDetail.bookings
            if(this.bookingToEdit) return this.orderDetail.bookings.filter(item => item.booking.id === this.bookingToEdit )
        },
        validbooking(){
            const owner = this.selectedOrder?.customer?.typeCustomer != 2
            const status = this.bookingSelected && this.bookingSelected?.status != 2 && this.bookingSelected?.status != 10 && this.bookingSelected?.status != 7
            if (!status) showAlertMessage( 'Reserva inválida', 'InfoIcon', 'No puede editar esta reserva debido a que está cancelada o ya se ha operado', 'warning', 4000, 'bottom-right')
            if (!owner) showAlertMessage( 'Reserva inválida', 'InfoIcon', 'No puede editar esta reserva debido a que es de un propietario', 'warning', 4000, 'bottom-right')
            return owner == true && status == true
        }
    },
    methods: {
        ...mapActions('mySales',['getRooms']),
        ...mapMutations('mySales',['setRoomsOrderDetail','setDataSearchForm2']),
        async searchRooms(){
            this.isLoadingRooms = true
            const rooms = await this.getRooms( this.searchForm )
            // console.log(formatBookings(rooms))
            this.setRoomsOrderDetail( rooms )
            this.isLoadingRooms = false
        },
        setFilterForRooms(rawData){
			let data = toJson(rawData)
			const promoCode = data.promoCode != null ? data.promoCode : ''
			const promoCodeManual = data.promoCodeManual != '' ? data.promoCodeManual : ''
			const promoCodeToUse = promoCode == '' ? promoCodeManual : promoCode
            if (data.success) this.validaStep = data.success
            //añado propiedades restantes
            data.children = data.numberChildren
            data.idioma = data.language || 'en'
            data.isManualRate = false
            data.manualRate = ''
            data.promotionCode = promoCodeToUse || ''
            data.promoCodeManual = promoCodeManual
            data.rateplanid = ''
            data.tarifa = ''

            this.setDataSearchForm2(data) //lo pongo en estado para ocuparlo como payload en peticion al resetear tarifas de un room
            // this.formatNewBooking()
        },
        formatNewBooking(){
            console.log(this.searchForm)
            const booking = this.bookingSelected
            if (!this.bookingSelected.guests.adults) this.bookingSelected.oldguests = structuredClone(this.bookingSelected.guests)

            //agrego datos que necesito
            booking.adults = this.searchForm?.adults
            booking.children = this.searchForm?.numberChildren
            booking.ageChildren = this.searchForm?.ninos
            booking.rangeDate = this.searchForm?.rangeDate
            booking.dateIn = this.searchForm?.dateIn
            booking.dateOut = this.searchForm?.dateOut
            booking.nights = this.searchForm?.nights

            if (!booking.guests || booking.guests.length == 0) {
                this.editbooking = formatGuestsInBooking(booking)
                const idx = toJson( this.editbooking?.guests?.adults.findIndex( main => main.mainGuest ))
                this.editbooking.guests.adults[idx].NamePrefix = this.editbooking?.nameprefix
                this.editbooking.guests.adults[idx].GivenName = this.editbooking?.givenname
                this.editbooking.guests.adults[idx].Surname = this.editbooking?.surname
                this.editbooking.guests.adults[idx].PhoneNumber = this.editbooking?.phonenumber
                this.editbooking.guests.adults[idx].Email = this.editbooking?.email
            } else this.editbooking = formatGuestsInMySalesBooking(booking)

            this.bookingSelected.guests = this.editbooking.guests

            return this.validbooking
        },
        updateMyBooking(){
            this.$emit("update-booking");
        }
    }
}
</script>
