<template>
    <b-card class="border">
        <b-card-body>
            <b-card-title style="font-size:1em" class="text-justify">
                <b-link class="card-link text-center" style="cursor:auto">
                    <h5>{{product.itemsold}}</h5>
                    <hr>
                </b-link>
            </b-card-title>
            <b-link class="card-link" style="cursor:auto">
                <b-row>
                    <b-col md="6" class="text-center">
                        <strong>Precio: </strong> {{ currency(product.unitPrice) }} <br>
                        <strong>Descuento: </strong> {{ currency(product.discount) }}
                    </b-col>

                    <b-col md="6" class="text-center" :class="windowWidth > 767 ? 'd-none' : '' ">
                        ...<br><strong>Qty: </strong> {{ product.qty }} <br> <strong>Total: </strong> {{ currency(product.total) }}
                    </b-col>
                    <b-col md="6" class="text-center" style="border-left-style:solid;border-left-width:thin;" :class="windowWidth < 768 ? 'd-none' : '' ">
                        <strong>Qty: </strong> {{ product.qty }} <br> <strong>Total: </strong> {{ currency(product.total) }}
                    </b-col>
                </b-row>
                <div v-if="product.beneficiario.fullname.length > 0 ">
                    <hr/>
                    <div class="text-center"><strong>Beneficiario: </strong>{{ product.beneficiario.fullname }} <br>
                    <strong>Correo: </strong>{{ product.beneficiario.email }} <br> <strong>Teléfono: </strong> {{ product.beneficiario.phone }} <br>
                    <span v-if="product.beneficiario.room"> <strong>Habitación: </strong> {{ product.beneficiario.room }} </span>
                    </div>
                </div>
                <hr/>
            </b-link>
            <div v-if="orderConfirmed">
                <b-row>
                    <b-col>
                        <span class="badge badge-success mt-1" v-if="product.confirmationdate">
                            Confirmed: {{ product.confirmationdate }}
                        </span>
                    </b-col>
                    <b-col>
                        <span class="badge badge-success mt-1" v-if="product.confirmationdate">
                            Code: {{ product.confirmationcode ? product.confirmationcode : 'N/A' }}
                        </span>
                    </b-col>
                    <b-col class="text-center">
                        <span :class=" product.operationby != null ? 'badge badge-success mt-1' : 'badge badge-warning mt-1'" v-if="product.confirmationdate">
                            {{product.operationby != null ? 'Operated' : 'Operation date'}}: {{ product.operationdate || 'N/A' }}
                        </span>
                    </b-col>
                    <b-col md="12" class="text-center">
                        <span class="badge badge-success mt-1" v-if="orderConfirmed">
                            La orden ya ha sido {{orderStatus}}. <br> No se puede editar la fecha/hora de operación.
                        </span>
                    </b-col>
                </b-row>
                <hr>
            </div>
            <b-row class="mt-1">
                <b-col md="3">
                    <span>Fecha de operación</span>
                    <b-input-group >
                        <b-form-input  type="date" class="form-control" :min="fechaActual" v-model="product.operationdate" readonly></b-form-input>
                        <b-input-group-append v-if="!isDisablebToEdit && !orderConfirmed">
                            <b-button variant="info" @click="openModalDateOpertion(product.id)" ><feather-icon icon="Edit2Icon" size="14" /></b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Hora de operación">
                        <vue-timepicker
                            :disabled="isDisablebToEdit || orderConfirmed"
                            v-model="product.operationtime"
                            input-width="100%"
                            placeholder="Ingrese hora de operación"
                            :minute-interval="30"
                        >
                            <template v-slot:clearButton>
                                <feather-icon icon="XCircleIcon" style="color:black;"/>
                            </template>
                        </vue-timepicker>
                    </b-form-group>
                </b-col>
                <b-col  md="6">
                    <span>Referencia de reserva</span>
                    <b-input-group >
                        <b-form-input  type="text" class="form-control"  v-model="product.bookingReference" ></b-form-input>
                    </b-input-group>
                 </b-col>
                <b-col md="6">
                    <b-form-group label="Nota interna">
                    <b-form-textarea :disabled="isDisablebToEdit" v-model="product.note" placeholder="Escribe la nota de uso interno" :maxlength="max" rows="2" >
                    </b-form-textarea>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Nota cliente">
                    <b-form-textarea :disabled="isDisablebToEdit" v-model="product.clientnote" placeholder="Escribe la nota para el cliente" :maxlength="max" rows="2" >
                    </b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col style="padding-bottom:1rem;">
                    <CouponLink :typeItem="typeItems" :product="product"/>
                </b-col>
                <b-col style="padding-bottom:1rem;">
                    <div v-if=" product.orderdetailbeo.id != '' ">
                        <div v-if="product.orderdetailStatus == 1 ||
                            product.orderdetailStatus == 3 ||
                            product.orderdetailStatus == 4 ||
                            product.orderdetailStatus == 7">
                        </div>
                        <a
                            class="btn btn-primary btn-block"
                            :href="BEOLink + product.order +'/'+ product.id"
                            target="_blank" rel="noopener noreferrer"
                        > SOS
                        </a>
                    </div>
                </b-col>
                <b-col style="padding-bottom:1rem;" v-if="isDevice">
                    <TicketLink :typeItem="typeItems" :product="product" v-if="isPaid"/>
                </b-col>
                <b-col>
                    <b-button v-if="!isDisablebToEdit" class="btn-block" variant="primary"
                        @click="setOperationDateTimeNote(product)"
                    > Guardar
                    </b-button>
                </b-col>
            </b-row>
            <b-modal
                :id="'modal-date-oparation' + product.id"
                ok-only no-close-on-backdrop size="md" ok-title="Ok" centered :title="product.itemsold"
            >
                <DateOperation :product="product"/>
            </b-modal>
        </b-card-body>
    </b-card>
</template>

<script>
import * as moment from 'moment'
import { mapActions, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import { currentDate, detectMobileDevice, toCurrency } from '@/helpers/helpers'
import DateOperation from '@/modules/mySales/components/orderDetail/DateOperation'
import CouponLink from '@/modules/mySales/components/orderDetail/CouponLink'
import TicketLink from '@/modules/mySales/components/orderDetail/TicketLink'
import { utils } from '@/modules/mySales/mixins/utils'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
	mixins:[utils],
    directives: {
        Ripple
    },
    props:{
        product:{
            type: Object,
            required: true
        },
        typeItems:{
            type: String,
            required: true
        }
    },
    components: {
        VueTimepicker,
        DateOperation,
        CouponLink,
        TicketLink
    },
    data() {
        return {
            max: 255,
            isOpenModalDateOperation: false,
            fechaActual: currentDate(),
            BEOLink: process.env.VUE_APP_ORDER_DETAIL_BEO_PDF,
            isDevice: detectMobileDevice(),
        }
    },
    computed: {
        ...mapState('auth',['user']),
        ...mapState('appConfig',['windowWidth']),
        ...mapState('mySales',['selectedOrder']),
        ...mapState('start',['currencies']),
        isPaid(){
            return this.product?.orderdetailstatusname == 'Paid'
        },

        //si isDisablebToEdit return true, no es editable
        isDisablebToEdit(){
            return this.lockMySalesEditForms(this.product)
        },
        orderConfirmed(){
            return this.product?.confirmationdate != null || this.product?.operationby != null
        },
        orderStatus(){
            if (this.product?.confirmationdate && this.product?.operationby) return 'confirmada y operada'
            else if (this.product?.confirmationdate) return 'confirmada'
            else if (this.product?.operationby) return 'operada'
        }
    },
    methods: {
        ...mapActions('mySales',['updateOperationDateTimeNote']),
        openModalDateOpertion(id) {
            this.$root.$emit("bv::show::modal", "modal-date-oparation" + id)
        },
        async setOperationDateTimeNote(product){
            const payload = { orderdetail:product.id, operationdate:product.operationdate, operationtime:product.operationtime,
                 note:product.note, idUser: this.user.idUser, clientnote:product.clientnote ,bookingReference:product.bookingReference}
            // console.log(payload)
            await this.updateOperationDateTimeNote(payload)
        },
        currency(data){
            return toCurrency(parseFloat(data) * parseFloat(this.selectedOrder.currencyExchangeRate), this.selectedOrder.currency.code) +' '+ this.selectedOrder.currency.code
        }
    }
}  
</script>
