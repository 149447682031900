var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"border"},[_c('b-card-body',[_c('b-card-title',{staticClass:"text-justify",staticStyle:{"font-size":"1em"}},[_c('b-link',{staticClass:"card-link text-center",staticStyle:{"cursor":"auto"}},[(_vm.product.beneficiario.fullname.length > 0)?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.product.beneficiario.fullname))])]):_c('div',[_c('h3',[_vm._v(_vm._s(_vm.product.customer.name))])]),_c('label',[_vm._v(_vm._s(_vm.product.itemsold))]),_c('hr')])],1),_c('ValidationObserver',{ref:"observerBeneficiary",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.product.beneficiario.fullname != null)?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Nombre"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"disabled":!_vm.enableActions,"type":"text","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.product.beneficiario.fullname),callback:function ($$v) {_vm.$set(_vm.product.beneficiario, "fullname", $$v)},expression:"product.beneficiario.fullname"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Email"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"disabled":!_vm.enableActions,"type":"email","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.product.beneficiario.email),callback:function ($$v) {_vm.$set(_vm.product.beneficiario, "email", $$v)},expression:"product.beneficiario.email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"","name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Teléfono"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"disabled":!_vm.enableActions,"oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","maxlength":"20","type":"text","state":errors[0] ? false : (valid ? true : null)},on:{"keypress":_vm.formatPhoneNumber},model:{value:(_vm.product.beneficiario.phone),callback:function ($$v) {_vm.$set(_vm.product.beneficiario, "phone", $$v)},expression:"product.beneficiario.phone"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"","name":"room"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Habitación"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"disabled":!_vm.enableActions,"maxlength":"10","type":"text","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.product.beneficiario.room),callback:function ($$v) {_vm.$set(_vm.product.beneficiario, "room", $$v)},expression:"product.beneficiario.room"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1)],1):_vm._e(),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[(_vm.enableActions)?_c('b-button',{staticClass:"btn-block",attrs:{"variant":"primary","disabled":invalid || _vm.isUpdatingBeneficiary},on:{"click":function($event){return _vm.setBeneficiaryCart(_vm.product)}}},[(_vm.isUpdatingBeneficiary)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Actualizar ")],1):_vm._e()],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }