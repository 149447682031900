<template>
    <b-card class="border">
        <b-card-body>
          <ValidationObserver ref="observer" v-slot="{ invalid }" >
        <b-row>
  
          <b-col md="12"><h3>Detalle de upgrade</h3></b-col>
          <b-col md="6">
  
            <ValidationProvider name="Hotele" rules="required">
            <b-form-group
              slot-scope="{ valid, errors }"
              label="Seleccione hotel*"
            >
              <b-form-select
                :state="errors[0] ? false : valid ? true : null"
                v-model="product.orderUpgrade.idhotel"
                @change="handlerResort"
              >
                <option :value="null">Seleccione hotel</option>
                <option v-for="hotel in hotels" :key="hotel.id" :value="hotel.id">
                  {{ hotel.name }}
                </option>
              </b-form-select>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          
          </b-col>
            <b-col md="6">
                <ValidationProvider name="rangeDateValues" rules="required">    
                 <b-form-group label="Fecha incio - Fecha final*"     slot-scope="{ valid, errors }">
                                      <flat-pickr
                                          v-model="rangeDateValues"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          class="form-control"
                                          @input="setCheckInChekOut"
                                          :config="{ mode: 'range'}"
                                          :readonly="true"
                                      />
                                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                 </b-form-group>
                </ValidationProvider>
            </b-col>
                              
       <b-col md="3" v-if="product.orderUpgrade.typeUpgrade == 2">>
  
            <ValidationProvider name="origincategory" rules="required" >
                <b-form-group label="Categoría origen" slot-scope="{ valid, errors }">
                <b-form-select
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="product.orderUpgrade.origincategory"
                    
                >
                    <option :value="null" >Seleccione una opción</option>
                    <option
                    v-for="(mealPlanItem, index) in mealPlan"
                    :key="index"
                    :value="mealPlanItem.id"
                    >
                    {{ mealPlanItem.name }}
                    </option>
                </b-form-select>
                <b-form-invalid-feedback
                    >{{ errors[0] }}
                </b-form-invalid-feedback>
                </b-form-group>
            </ValidationProvider>
  
        </b-col>

        <b-col md="3" v-if="product.orderUpgrade.typeUpgrade == 2">>
  
            <ValidationProvider name="destinationcategory" rules="required" >
                <b-form-group label="Categoría destino" slot-scope="{ valid, errors }">
                <b-form-select
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="product.orderUpgrade.destinationcategory"
                    
                >
                    <option :value="null">Seleccione una opción</option>
                    <option
                    v-for="(mealPlanItem, index) in mealPlan"
                    :key="index"
                    :value="mealPlanItem.id"
                    >
                    {{ mealPlanItem.name }}
                    </option>
                </b-form-select>
                <b-form-invalid-feedback
                    >{{ errors[0] }}
                </b-form-invalid-feedback>
                </b-form-group>
            </ValidationProvider>
  
        </b-col>
  
        <b-col md="3" v-if="product.orderUpgrade.typeUpgrade == 1">
              <ValidationProvider rules="required" name="Categoría origen">
                <b-form-group
                 
                  slot-scope="{ valid, errors }"
                  label="Categoría origen"
                >
                  <b-form-select
                    v-model="product.orderUpgrade.origincategory"
                    :state="errors[0] ? false : valid ? true : null"
                    
                  >
                  <option :value="null">Seleccione una opción</option>
                  <option
                    v-for="roomsItems in roomsTypes"
                    :key="roomsItems.id"
                    :value="roomsItems.code"
                  >
                    {{ roomsItems.name }} | {{roomsItems.code  }}
                  </option>
                  </b-form-select>
                </b-form-group>
              </ValidationProvider>
            </b-col>
  
            
            <b-col md="3" v-if="product.orderUpgrade.typeUpgrade ==1">
              <ValidationProvider rules="required" name="Categoría destino" >
                <b-form-group
                  
                  slot-scope="{ valid, errors }"
                  label="Categoría destino"
                >
                  <b-form-select
                    v-model="product.orderUpgrade.destinationcategory"
                    :state="errors[0] ? false : valid ? true : null"
                   
                  >
                  <option :value="null">Seleccione una opción</option>
                  <option
                    v-for="roomsItems in roomsTypes"
                    :key="roomsItems.id"
                    :value="roomsItems.code"
                  >
                    {{ roomsItems.name }} | {{roomsItems.code  }}
                  </option>
                  </b-form-select>
                </b-form-group>
              </ValidationProvider>
            </b-col>
     
        </b-row>
        <b-row class="justify-content-end">
            
            <b-col md="3">
                <b-button
                    v-if="enableActions" class="btn-block" variant="primary"    
                    :disabled="invalid"
                    @click="setOrderUpgrade(product)" 
                > <b-spinner small v-if="isUpdatingUpgrades"/> Actualizar
                </b-button>
            </b-col>
        </b-row>
    </ValidationObserver>  
    </b-card-body>
    </b-card>
 
  </template>
  
  <script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  import { toJson, currentDate } from '@/helpers/helpers'
  import { mealPlan } from '@/data/data'
  import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
  import 'vue2-timepicker/dist/VueTimepicker.css'
  import flatPickr from 'vue-flatpickr-component'
  import { market } from '@/modules/shop/mixins/market'
  
  export default {
    async mounted() {
      this.handlerResort(this.product.orderUpgrade.idhotel)
  
      var hotels= await this.fetchHotel();
      this.setHotels(hotels)
      },
    props: {
      product: {
        type: Object,
        required: true,
      },
    },
    mixins: [market],
     components: {
      VueTimepicker,
      flatPickr
    },
      watch: {
          'product.orderUpgrade': {
              deep: true,
              handler() {
        
          this.setIfRequiredFieldsFilled()
         
              },
          },	
  
      },
    data () {
      return {
        max: 255,
        mealPlan, 
        rangeDateValues:`${this.product.orderUpgrade.datestart} to ${this.product.orderUpgrade.dateend}`  ,
        roomsTypes:{},
        idHotel: "",
        fechaActual: currentDate(),
        isUpdatingUpgrades:false
      }
    },
    computed:{
      ...mapState("start", ["hotels"]),
      ...mapState('auth',['user']),
      enableActions() {
            const seller = this.product?.saleuser_id == this.user?.idUser
            const channel = this.product?.saleuserchannelid == this.user?.canalventa?.id

            const valid = (seller || channel) || false

            return valid
        },
    },
    methods: {
      ...mapActions('mySales',['updateUpgrades']),
      ...mapActions("start", ["fetchHotel"]),
      ...mapActions('groups', ['getRoomTypesHousing']),
      ...mapMutations("shop", [ "setIfRequiredFieldsFilled"]),
      ...mapMutations('start',['setHotels']),      
        async handlerResort(idResort){
         if(idResort){
            this.roomsTypes = await this.getRoomTypesHousing(idResort)                    
                 
         }
         if(idResort!= this.product.orderUpgrade.idhotel){
            this.product.orderUpgrade.origincategory =null
             this.product.orderUpgrade.destinationcategory =null 
         }
        
        },

        setCheckInChekOut(rangeDate) {
              if (rangeDate.includes("to")) {
                  const splitDate = rangeDate.split(" to ")
                  this.product.orderUpgrade.datestart = splitDate[0]
                  this.product.orderUpgrade.dateend = splitDate[1]
              } else {
                  this.product.orderUpgrade.datestart = null
                  this.product.orderUpgrade.dateend = null
          
              }
          },

          async setOrderUpgrade(orderDetail){
            const { orderUpgrade } = orderDetail
            this.isUpdatingUpgrades = true
            const payload = {
                data: {
                ...orderUpgrade
                }
                
            }
            await this.updateUpgrades(payload)
            this.isUpdatingUpgrades = false
    }
    }
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .contentUpgrades {
   
      min-height: 468px;
  }
  .contentUpgrades h3 {
      padding: 10px 0 8% 0;
  }
  </style>