<template>        
    <b-row>
      <b-col class="col-md-12">
        <div class="table-responsive">    
          <table class="table table-sm table-striped" v-if="bookingListUpdate.length">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Cliente</th>                                                               
                    <th scope="col">Reserva</th>
                    <th scope="col">Pax</th>                                                                                       
                    <th scope="col">Fechas</th>                     
                    <th scope="col">Noches</th>     
                    <th scope="col">Plan</th>                         
                    <th scope="col">Status</th> 
                    <th scope="col">Promoción</th> 
                    <th scope="col">Tarifa inicial</th>                                                                      
                    <th scope="col">Total</th>                                        
                </tr>
            </thead>
            <tbody>                                    
                <tr v-for="(item, index) in bookingListUpdate" :key="index">                                                                        
                    <td scope="row">
                      <div>
                        <!-- <strong>Orden:</strong> {{ item.order }} <br>  -->
                        <strong>Name:</strong> {{ item.booking.nameprefix }} {{ item.booking.givenname}} {{ item.booking.surname }} <br> 
                        <strong>Email:</strong> {{ item.booking.email}} <br> 
                        <strong>Phone:</strong> {{ item.booking.phonenumber}}
                      </div>
                    </td>                                                           
                    <td scope="row">
                      <div>
                        <strong>Reserva:</strong> {{ item.booking.dingusbooking }}  <br> 
                        <strong>Hotel:</strong> {{ item.booking.hotelname}} <br>                         
                        <strong>Habitación:</strong> {{ item.booking.roomtypename}}                        
                      </div>
                    </td> 
                    <td scope="row">
                      <div>
                        <strong>Adultos:</strong> {{ item.booking.previousAdults }}  <br> 
                        <strong>Niños:</strong> {{ item.booking.children}} <br>                         
                      </div>
                    </td>   
                    <td scope="row">
                      <div>
                        <strong>Check in:</strong> {{ item.booking.previousDateIn }}  <br> 
                        <strong>Check out:</strong> {{ item.booking.previousDateOut}}                                                                        
                      </div>                                                           
                    </td>
                    <td scope="row">{{item.booking.previousNights}}</td>
                    <td scope="row">
                      <div>
                        <strong>Meal Plan:</strong> {{ item.booking.mealplancode }}  <br> 
                        <strong>Rate Plan:</strong> {{ item.booking.rateplancode}} <br>                         
                      </div>
                    </td> 
                     
                    <td scope="row">{{ statusbooking(item) }} </td> 
                    <td scope="row">{{item.booking.promotioncode}}</td>   
                    <td scope="row">$ {{item.booking.rateInit}}</td>                    
                    <td scope="row">$ {{item.total}}</td>  
                </tr>   
            </tbody>
          </table>     
        </div>
           
           <b-alert show variant="danger" v-if="!bookingListUpdate.length">
                <div class="alert-body text-center"> <span>no hay datos</span></div>                
            </b-alert>  
        </b-col>
    </b-row>                 
</template>

<script>


export default {  
  props:{
    bookingListUpdate:{
      type:Array,
      require:true
    
    }
  },
  methods: {    
    statusbooking( item ){
      const { orderdetailstatusname } = item
      return orderdetailstatusname
    }        
  }
}
</script>