<template>
  <div>
    <b-row class="mb-2">
      <b-col>
        <div class="float-right">
          <b-form-checkbox
            v-model="isRateLimit"
            :value="true"
            class="custom-control-success"
          >
            Es tarifa límite
          </b-form-checkbox>
        </div>

        <div v-if="room.promoCodeManual != ''">
          <div>
            <strong>Tarifa inicial :</strong>
            <b-badge variant="warning">
              <strong> ${{ bookingSelected.rateInit }} </strong>
            </b-badge>
          </div>
          <div>
            <strong>Tarifa inicial:</strong>
            <b-badge
              variant="warning"
              :class="
                room.detailSelected.priceMatchTax != '' ? 'underline-price' : ''
              "
            >
              ${{ room.priceInit }}
            </b-badge>
          </div>
        </div>

        <div v-else>
          <div>
            <strong>Tarifa inicial:</strong>
            <b-badge
              variant="warning"
              :class="
                room.detailSelected.priceMatchTax != '' ? 'underline-price' : ''
              "
            >
              ${{ room.priceInit }}
            </b-badge>
          </div>
        </div>
        <div v-if="room.detailSelected.PromotionName != ''">
          <strong>Promoción: </strong>{{ room.detailSelected.PromotionName }}
        </div>

        <div v-if="room.showingRates">
          <strong>Tarifa Manual:</strong> ${{ room.detailSelected.manualPrice }}
        </div>
        <!-- <div v-if="room.detailSelected.priceMatchTax != ''">
          <strong>Tarifa con promoción: </strong>${{room.detailSelected.priceMatchTax }} 
        </div> -->
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group :label="'Divisa: ' + room.detailSelected.divisa.code">
          <b-form-select v-model="room.detailSelected.divisa">
            <option v-for="cur in currencies" :key="cur.id" :value="cur">
              {{ cur.code }}
            </option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="8">
        <b-form-group label="Tarifa" v-if="!isLoadingRates">
          <b-form-input
            placeholder="Tarifa"
            type="text"
            @keypress="onlyNumber"
            @change="setManualPrice"
            v-model="room.detailSelected.manualPrice"
          />
        </b-form-group>
        <div class="text-center" v-if="isLoadingRates">
          <b-spinner label="Loading..." variant="success" /><br />
          <strong>Buscando tarifa</strong>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-badge variant="success">
          <feather-icon icon="DollarSignIcon" class="mr-25" />
          <span>{{ precioConversion }} </span>
        </b-badge>
      </b-col>
      <b-col>
        <div class="float-right">
          <b-button
            :class="room.detailSelected.PromotionName != '' ? 'mr-1' : ''"
            variant="primary"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            title="Buscar por tarifa manual"
            :disabled="
              room.detailSelected.manualPrice == '' || !room.showingRates
            "
            @click="checkPriceMatchByManualRate"
            ><feather-icon icon="SearchIcon" />
          </b-button>
          <b-button
            v-if="room.detailSelected.PromotionName != '' && !isRateLimit"
            variant="primary"
            @click="handlerUpdateBooking"
          >
            Autorizar <feather-icon icon="UnlockIcon" />
          </b-button>
          <b-button
            v-if="room.detailSelected.PromotionName != '' && isRateLimit"
            variant="primary"
            @click="handlerUpdateBooking"
            :disabled="!room.showingRates"
          >
            <feather-icon icon="ShoppingCartIcon" size="16" /> Actualizar
          </b-button>
        </div>
      </b-col>
    </b-row>
    <AuthorizeUpdateBooking
      :room="room"
      @set-payload-update-booking="setPayloadUpdateBooking"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import {
  toJson,
  toDecimal,
  stringAleatorio,
  currentDate,
} from "@/helpers/helpers";
import { VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AuthorizeUpdateBooking from "@/modules/mySales/components/orderDetail/AuthorizeUpdateBooking";

export default {
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
    isLoadingRates: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    AuthorizeUpdateBooking,
  },
  data() {
    return {
      isRateLimit: false,
      hashModal: stringAleatorio(),
    };
  },
  computed: {
    ...mapState("start", ["promoCodes", "currencies"]),
    ...mapState("mySales", [
      "bookingSelected",
      "searchForm",
      "userAuthUpdateBooking",
    ]),
    ...mapState("auth", ["user"]),
    precioConversion() {
      const precioToUse =
        this.room.detailSelected.priceMatchTax != ""
          ? this.room.detailSelected.priceMatchTax
          : this.room.detailSelected.AmountAfterTax;
      const conversion = precioToUse * this.room.detailSelected.divisa.value;
      return toDecimal(conversion);
    },
  },
  methods: {
    ...mapMutations("mySales", ["setManualPriceSelectedRoom"]),
    setManualPrice() {
      const keyRoom = this.room.keyRoom;
      const manualRate = this.room.detailSelected.manualPrice;
      const payload = { keyRoom, manualRate };
      this.setManualPriceSelectedRoom(payload);
    },
    onlyNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        event.preventDefault();
      }
    },
    checkPriceMatchByManualRate() {
      const manualRate = parseFloat(this.room.detailSelected.manualPrice);
      const { hoteldinguscode, language } = this.bookingSelected;
      const { dateIn, dateOut, RoomTypeCode } = this.room;
      const { ninos, adults, children } = this.searchForm;
      let arrayNinios = [];
      if (ninos && ninos.length > 0) {
        ninos.forEach((edad) => {
          arrayNinios.push(edad);
        });
      }
      this.room.detailSelected.allowPriceMatch = true;
      const payload = {
        adults,
        children,
        dateIn,
        dateOut,
        hotel: hoteldinguscode,
        idioma: language,
        isManualRate: true, // en true para price match manual price
        keyRoom: this.room.keyRoom, //lo ocupo de manera interna
        manualRate, //requirido para price match manual price
        ninos: toJson(arrayNinios),
        priceInit: parseFloat(this.room.detailSelected.priceInit), //lo ocupo de manera interna, no necesario para el request
        promotionCode: "",
        rate: parseFloat(this.room.detailSelected.priceInit), //requirido para price match manual price
        rateplanid: "",
        roomCode: RoomTypeCode,
        tarifa: this.room?.detailSelected?.RatePlanCode || "", // Es requerido,
        vendorcode: this.user?.canalventa?.dingusvendorcode || "",
      };
      // console.log( payload )
      this.$emit("check-price-match-by-promotion-code", payload);
    },
    handlerUpdateBooking() {
      if (this.isRateLimit) {
        const payload = {
          authorizationUser: "",
          dateAuthorization: null,
          isAuthorization: false,
          isRateLimit: true,
          urlAuthorization: null,
        };
        this.$emit("update-booking", payload);
      }
      if (!this.isRateLimit) {
        this.openModal();
      }
    },
    setPayloadUpdateBooking() {
      const { idUser, urlAuthorization } = this.userAuthUpdateBooking;
      this.closeModal();
      const payload = {
        authorizationUser: idUser,
        dateAuthorization: currentDate(),
        isAuthorization: true,
        isRateLimit: false,
        urlAuthorization,
      };
      this.$emit("update-booking", payload);
    },
    openModal() {
      this.$root.$emit("bv::show::modal", "update-booking" + this.room.keyRoom);
    },
    closeModal() {
      this.$root.$emit("bv::hide::modal", "update-booking" + this.room.keyRoom);
    },
  },
};
</script>
