<template>
    <div>
        <b-tabs v-model="tabIndex">
            <b-tab title="Detalles">
                <b-row>
                    <b-col class="col-md-6" v-for="product in orderDetailProducts" :key="product.id">
                        <NoteProduct :product="product" :typeItems="typeItems"/>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Beneficiario">
                <b-row>
                    <b-col class="col-md-6" v-for="product in orderDetailProducts" :key="product.id">
                        <TabBeneficiary :product="product" :typeItems="typeItems"/>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Upgrades" v-if="orderHasProductsWithUpgrades"> 
                <b-row >
                    <b-col class="col-md-7" v-for="transfer in orderDetailProducts" :key="transfer.id">
                        <TabUpgrades :product="transfer" :typeItems="typeItems" />
                    </b-col>
                </b-row>
            </b-tab>  
            <b-tab title="SOS" v-if="orderHasProductsWithBEO">
                <b-row>
                    <b-col class="col-md-6" v-for="product in orderDetailProducts" :key="product.id">
                        <BEOProduct :product="product" v-if="product.orderdetailbeo.id"/>
                    </b-col>
                    <b-col class="col-md-12" v-if="!orderHasProductsWithBEO">
                        <b-card>
                            <b-alert show variant="danger">
                                <div class="alert-body text-center"> <span>Esta orden no tiene productos con SOS definidos</span></div>
                            </b-alert>
                        </b-card>
                    </b-col>
                </b-row>
            </b-tab>
        </b-tabs>
        <!-- Control buttons-->
        <div class="text-center">
            <b-button-group class="mt-1">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="tabIndex--"
            >
                Anterior
            </b-button>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="tabIndex++"
            >
                Siguiente
            </b-button>
            </b-button-group>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import NoteProduct from '@/modules/mySales/components/orderDetail/NoteProduct'
import BEOProduct from '@/modules/mySales/components/orderDetail/BEOProduct'
import TabBeneficiary from '@/modules/mySales/components/orderDetail/TabBeneficiary'
import TabUpgrades from '@/modules/mySales/components/orderDetail/NoteUpgrades'

export default {
directives: {
    Ripple,
  },
  components: {
    NoteProduct,
    BEOProduct,
    TabBeneficiary,
    TabUpgrades
  },
  data() {
    return {
      tabIndex: 0,
      typeItems: 'products'
    }
  }, 
  computed: {    
    ...mapGetters('mySales',['orderDetailProducts']),
    orderHasProductsWithBEO(){
        return this.orderDetailProducts.find( product => product.orderdetailbeo.id != "" )
    },
    orderHasProductsWithUpgrades(){
        return this.orderDetailProducts.find( product => product.orderUpgrade.dateend != '' )
    }
  },
  methods: {
    
  }
}
</script>

