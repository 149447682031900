<template>
  <div>
    <div class="float-right" v-if="room.detailSelected.AmountAfterTax != room.detailSelected.priceInit || !room.rates.length ">
      <b-button
        size="sm"
        variant="warning"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-tooltip.hover.top="'Regresar a tarifa original'"
        @click="priceMatchInitial"
      >
        <feather-icon icon="RotateCcwIcon"/>
      </b-button>
    </div>
    <b-tabs>
      <b-tab active title="Descuento" >
        <PriceMatchPromoCode
          :isLoadingRates="isLoadingRates"
          :room="room"
          @check-price-match-by-promotion-code="priceMatchRoom"
          @price-match-initial="priceMatchInitial"
          @update-booking="updateMyBooking"
        />
      </b-tab>
      <!-- -->
      <b-tab title="Price Match / Tarifa límite" v-if="room.detailSelected.allowPriceMatch">
        <PriceMatchManualPrice
          :isLoadingRates="isLoadingRates"
          :room="room"
          @check-price-match-by-promotion-code="priceMatchRoom"
          @update-booking="updateMyBooking"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
// Helpers
import { toJson, showAlertMessage } from '@/helpers/helpers'
import { formatInfoRates } from '@/helpers/mySalesHelper'
import { setGuestsInPayload } from "@/helpers/shopHelper"

//components

import { VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import PriceMatchPromoCode from '@/modules/mySales/components/orderDetail/PriceMatchPromoCode'
import PriceMatchManualPrice from '@/modules/mySales/components/orderDetail/PriceMatchManualPrice'


export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props:{
    room:{
      type: Object,
      required: true,
    },
    isLoadingRates:{
      type: Boolean,
      required: true,
    }
  },
  components: {	PriceMatchPromoCode, PriceMatchManualPrice},
  computed:{
    ...mapState('mySales',['searchForm','bookingSelected','userAuthUpdateBooking']),
    ...mapState('auth',['user']),
  },
  methods:{
    ...mapActions('mySales',['fetchRoomPriceMatch','updateBooking','getInitialDataOrderDetails']),
    ...mapMutations('mySales',['setRoomsBookingSelected','setaBookingIsUpdating','setBookingSelectedRoom','unsetDataUserAuthorization','setDataSearchForm','setOrderDetail','setBookingToEdit']),
    async priceMatchRoom( data ){
      data.allowPriceMatch = data.isManualRate // si es isManualRate, allowPriceMatch es true
      this.$emit('set-is-loading-rates', true)
      const rooms = await this.fetchRoomPriceMatch(data) //pido nuevas tarifas
      this.setRoomsBookingSelected(rooms)
      this.$emit('set-is-loading-rates', false)
    },
    async priceMatchInitial(){
      //armo bien el payload
      const payload = toJson({...this.searchForm })
      const roomCode = this.room.detailSelected.RoomTypeCode
      payload.roomCode = roomCode
      payload.keyRoom = this.room.keyRoom, //lo ocupo de manera interna
      payload.priceInit = this.room.priceInitDefault, //lo ocupo de manera interna
      payload.allowPriceMatch = true
      delete payload.manualRate
      this.$emit('set-is-loading-rates', true)
      // console.log( payload )
      const rooms = await this.fetchRoomPriceMatch( payload ) //se pide las tarifas del room originales
      this.setRoomsBookingSelected(rooms)
      this.$emit('set-is-loading-rates', false)
    },
    async updateMyBooking( params ){
      const { authorizationUser, dateAuthorization, isAuthorization, isRateLimit, urlAuthorization } = params
      const { id, email, firstpay, givenname, hotel, inclusions, nameprefix, phonenumber,
             surname, termscondition, order, orderDetail, partial, urlimage, dingusbooking, guests } = this.bookingSelected
      const { multimedias, RoomTypeName } = this.room //
      const { ninos, adults, children, dateIn, dateOut, idioma, nights } = this.searchForm
      const { MealPlanCodes, PromotionCode, priceInit, manualPrice, RatePlanCode, RatePlanID, MealPlanDescription, RoomTypeCode, InfoRates } = this.room.detailSelected
      const manualPriceFormat = manualPrice != '' ? parseFloat(manualPrice) : '0'
			const infoRates = ( InfoRates && InfoRates.length ) ? InfoRates : []

      let arrayNinios = []
      if( ninos && ninos.length > 0){
        ninos.forEach( (edad) => {
          arrayNinios.push(edad)
        })
      }
      const payloadBooking = {
        adults,
        authorizationUser,
        children,
        guests: setGuestsInPayload(guests),
        dateAuthorization, // es nulo por defecto, fecha tiene valor solo si viene de modal de autorización,
        datein: dateIn,
        dateout: dateOut,
        dingusbooking,
        email,
        firtpay: firstpay,
        givenname,
        hotel,
        id, //propiedad extra, lo requiero para armar el payload, es el id del item (del booking)
        inclusions, // viene en el el booking selected
        inforates: formatInfoRates(infoRates) , //nuevo valor
        isAuthorization, // si hay idUserAutoriza es true, sino false
        isRateLimit, // false por defecto, setear true si este viene con el check desde pricematch en tab2
        language: idioma,
        mealplancode: MealPlanCodes,
        multimedias: toJson(multimedias) || null,
        nameprefix,
        nights,
        ninos: toJson(arrayNinios),
        order, //propiedad extra, lo requiero para armar el payload, el numero de order
        orderDetail, //propiedad extra, lo requiero para armar el payload, el numero de order
        partial: partial || false, // boolean, sino viene sería false
        phonenumber,
        promotioncode: PromotionCode,
        rateInit: priceInit,
        rateManual: manualPriceFormat,
        rateplancode: RatePlanCode,
        rateplanid: RatePlanID,
        rateplanname: MealPlanDescription,
        roomtypecode:RoomTypeCode,
        roomtypename: RoomTypeName,
        surname,
        termscondition: termscondition || null, //sino viene con valor, sería nulo
        total: this.room.detailSelected.AmountAfterTax,
        urlAuthorization, // img si sucede autorizacion, va la url
        urlimage,
        vendorcode: this.user.canalventa.dingusvendorcode || '',
      }

      const payloadFinal = { ordersdetail: orderDetail, bookingid: id, order, orderbooking: payloadBooking }
      // return console.log( payloadFinal )

      this.setaBookingIsUpdating(true) // para el loader
      this.setDataSearchForm(null) // limpio los datos del state searchForm, en nulo
      
      this.setBookingSelectedRoom( null ) // pongo nulo booking selected
      this.unsetDataUserAuthorization() // reseteo los valores del state userAuthUpdateBooking
       
      await this.$emit("update-booking");
      this.setBookingToEdit( null ) //muto bookingToEdit en nulo
      const { status, message } = await this.updateBooking(payloadFinal)
      if( status ){
        showAlertMessage('OK', 'BellIcon', message, 'success', 4000,  'bottom-right')
       
      }
      if( !status ) showAlertMessage('Error', 'InfoIcon', message, 'danger', 4000, 'bottom-right')
      setTimeout(() => {
        this.setaBookingIsUpdating(false) // detengo el loader
      }, 1500)
    }
  }
}
</script>

