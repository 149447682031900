<template>
  <div> 
    <b-card v-if="!isGettingDetailOrder">
        <div class="float-right">
          <b-button size="sm" :to="{ name: 'my-sales'}" variant="warning" v-b-tooltip.hover.top="'Regresar a mis ventas'" @click="quitSelectedOrder">
          <b-icon-arrow-left/>
          </b-button>
        </div>
        <div class="float-right toLeft">
          <b-badge :variant="manageStatusOrder">{{invoice}}</b-badge>
        </div>
      <b-tabs pills v-if="existsItemsInOrder">

        <!-- Tab: Products -->
        <b-tab active v-if="orderDetailProducts.length">
          <template #title>
            <span style="font-size:14px;">🏷️</span>
            <span class="d-none d-sm-inline">Servicios</span>
          </template>
          <TabsProducts/>
        </b-tab>

        <!-- Tab: Experiences -->
        <b-tab v-if="orderDetailExperiences.length">
          <template #title>
            <span style="font-size:14px;">🏖️</span>
            <span class="d-none d-sm-inline">Experiencias</span>
          </template>
          <TabsExperiences/>
        </b-tab>

        <!-- Tab: Transfers -->
        <b-tab v-if="orderDetailTranfers.length">
          <template #title>
            <span style="font-size:14px;">🚐</span>
            <span class="d-none d-sm-inline">Transportaciones</span>
          </template>
          <TabsTransfers/>
        </b-tab>

        <!-- Tab: Bookings -->
        <b-tab v-if="orderDetailBookings.length">
          <template #title>
            <span style="font-size:14px;">🏩</span>
            <span class="d-none d-sm-inline">Reservaciones</span>
          </template>
          <TabsBookings @update-booking="updadate"/>
        </b-tab>

        <!-- Tab: Packages -->
        <b-tab v-if="orderDetailPackages.length">
          <template #title>
            <span style="font-size:14px;">📦</span>
            <span class="d-none d-sm-inline">Paquetes</span>
          </template>
          <TabsPackages/>
        </b-tab>

        <!-- Tab: Events -->
        <b-tab v-if="orderDetailEvents.length">
          <template #title>
            <span style="font-size:14px;">🎉</span>
            <span class="d-none d-sm-inline">Eventos</span>
          </template>
          <TabsEvents/>
        </b-tab>

        <!-- Tab: Documentos -->
        <b-tab>
          <template #title>
            <span style="font-size:14px;">📄</span>
            <span class="d-none d-sm-inline">Registros de Orden</span>
          </template>
          <OrderRegisters />
        </b-tab>

      </b-tabs>
      <div style="text-align:center" v-if="!existsItemsInOrder">
        <h1>Ups</h1>
        <hr/>
        <b-alert show variant="danger">
          <div class="alert-body text-center"> <span>La orden no existe o no tiene elementos</span></div>
        </b-alert>
      </div>
    </b-card>

    <div class="text-center" v-if="isGettingDetailOrder">
      <b-alert show variant="info">
        <div class="alert-body text-center"> <span>Obteniendo los detalles de la orden</span></div>
        <b-spinner class="mb-2" label="Loading..." variant="success"/>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import TabsProducts from '@/modules/mySales/components/orderDetail/TabsProducts'
import TabsExperiences from '@/modules/mySales/components/orderDetail/TabsExperiences'
import TabsTransfers from '@/modules/mySales/components/orderDetail/TabsTransfers'
import TabsBookings from '@/modules/mySales/components/orderDetail/TabsBookings'
import TabsPackages from '@/modules/mySales/components/orderDetail/TabsPackages'
import TabsEvents from '@/modules/mySales/components/orderDetail/TabsEvents'
import OrderRegisters from '@/modules/mySales/components/orderDetail/OrderRegisters'
import { checkIfOrderDetailHasItems } from '@/helpers/mySalesHelper'
import { utils } from '@/modules/mySales/mixins/utils'

export default {
	mixins:[utils],
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    TabsProducts,
    TabsExperiences,
    TabsTransfers,
    TabsBookings,
    TabsPackages,
    TabsEvents,
    OrderRegisters
},
  async created() {
    this.orderUrl = this.$route.params.order
    this.setOrderFromUrl( this.orderUrl  )
  },
  async mounted(){
    if( this.payloadToGetDetailOrder ){
      this.isGettingDetailOrder = true
      if( !this.currencies.length ){
        const currencies = await this.fetchCurrencies()
        this.setCurrencies(currencies) //muto state currencies en start 
      }
     await this.updadate()
      this.isGettingDetailOrder =  false
    }
  },
  data() {
    return {
      orderUrl: '',
      tabIndex: 0,
      payloadToGetDetailOrder: null, 
      isGettingDetailOrder: false,
      existsItemsInOrder: false ,
      orderId: null,
    }
  },
  computed:{
    ...mapState('auth',['user']),
    ...mapState("start", ["currencies"]),
    ...mapState('appConfig',['layout']),
    ...mapState('mySales',['orderDetail', 'selectedOrder']),
    ...mapGetters('mySales',['orderDetailProducts','orderDetailTranfers','orderDetailBookings','orderDetailEvents','orderDetailPackages', 'orderDetailExperiences']),
    reference(){
      let reference = ''
      // if( this.orderDetailProducts.length > 0 ){
      //   reference = this.orderDetailProducts.find( product => product.reference != '' ).reference
      // }
      return reference
    },
    manageStatusOrder(){
      if (
        this.selectedOrder?.orderstatusname == 'Requested' ||
        this.selectedOrder?.orderstatusname == 'Modified' ||
        this.selectedOrder?.orderstatusname == 'Reserved'
      ) return 'warning'
      if (
        this.selectedOrder?.orderstatusname == 'Paid' ||
        this.selectedOrder?.orderstatusname == 'Audited' ||
        this.selectedOrder?.orderstatusname == 'Operated'
      ) return 'success'
      if (
        this.selectedOrder?.orderstatusname == 'Cancelled' ||
        this.selectedOrder?.orderstatusname == 'Annulled' ||
        this.selectedOrder?.orderstatusname == 'No Show'
      ) return 'danger'
    },
    invoice(){
      return this.selectedOrder?.invoice
    }
  },
  methods: {
    ...mapActions('mySales', ['getInitialDataOrderDetails','getAirlinesAndDestinations','getPromocodesAndCurrencies', 'fetchSingleOrder', 'getServiceTypesAndItineraryRules']),
    ...mapActions("start", ["fetchCurrencies"]),
    ...mapMutations('mySales', ['setCurrentOrder', 'unsetCurrentOrder']),
    ...mapMutations('start',['setCurrencies']),
    setOrderFromUrl( cadena ){    
      const arreglo = cadena.split("-", 2)
      const idOrder = parseInt(arreglo[1]) 
      const orderIsANumber = !isNaN(idOrder)
      if( orderIsANumber ){
        this.payloadToGetDetailOrder = { idOrder }
        this.orderId = idOrder
      }
    },
    async getAdditionalData(){
      if( this.orderDetail.transfers.length > 0 ) await this.getAirlinesAndDestinations()
      if( this.orderDetail.bookings.length > 0 ) await this.getPromocodesAndCurrencies()
      if (this.orderDetailTranfers?.length > 0) await this.getServiceTypesAndItineraryRules()
    },
    setReference( items ){
      if(items.length > 0 ){
        this.reference = items[0].reference
      }
    },
    async setSelectedOrder(orderId){
      const order = await this.fetchSingleOrder({
        idUser: 0,
        canalVenta: this.user.canalventa.id,
        pos: orderId
      })
      this.setCurrentOrder(order[0])
    },
    quitSelectedOrder(){
      this.unsetCurrentOrder()
    },
    async updadate(){
      await this.setSelectedOrder(this.orderId)
      const { orderDetail } = await this.getInitialDataOrderDetails( this.payloadToGetDetailOrder )
      this.existsItemsInOrder = checkIfOrderDetailHasItems( orderDetail  )
      await this.getAdditionalData()

    }
  }  
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
.btn-right{
  display: flex;
  justify-content: flex-end;
  margin-block-end: 1rem;
}
.card-link-dark{
  color: white;
}

.card-link-light{
  color: black;
}
.toLeft{
  padding-right: 1rem;
}
</style>
