<template>
  <div>
    <b-row>
      <b-col md="12" class="pb-1">
        <b-card-actions
          actionCollapse
          actionRefresh
          title="Histórico de cambios"
          :sub-title="`Orden: ${selectedOrder.id}`"
          :ref="`orderlog${selectedOrder.id}`"
          overlay-text="Cargando Historico de Orden."
          @refresh="refreshCard(`orderlog${selectedOrder.id}`)"
          class="border rounded pb-0"
        >
          <TableLogs v-if="(orderService.length > 0 && !isloadingOrderLogs)" :products="orderService" :fields="fields" :isLoadingLogs="isloadingOrderLogs" :showHeader="true" />

            <b-alert variant="info" :show="true" class="mb-0" v-else-if="isloadingOrderLogs && orderService.length == 0 ">
              <div class="alert-body text-center">
                <p>Cargando Historico de Orden.</p>
                <b-spinner/>
              </div>
            </b-alert>

            <b-alert variant="warning" :show="true" class="mb-0" v-else-if="(orderService.length == 0 && !isloadingOrderLogs)">
            <div class="alert-body text-center">
              Esta orden aún no cuenta con movimientos registrados.
            </div>
          </b-alert>
        </b-card-actions>
      </b-col>

      <b-col md="12">
        <b-card-actions
          actionCollapse
          actionRefresh
          title="Detalles de orden"
          :ref="`detaillog${selectedOrder.id}`"
          overlay-text="Cargando Historico de Detalles."
          @refresh="refreshCard(`detaillog${selectedOrder.id}`)"
          class="border rounded"
        >
          <b-tabs pills :justified="detailServiceLength > 3 && detailServiceLength < 8" v-if="detailServiceLength > 0 && !isloadingDetailLogs">

            <!-- Tab: Products -->
            <b-tab active v-if="detailService.Servicios && detailService.Servicios.length > 0">
              <template #title>
                <span style="font-size:14px;">🏷️</span>
                <span class="d-none d-sm-inline">Servicios</span>
              </template>
              <TableLogs :products="detailService.Servicios" :fields="productFields" :isLoadingLogs="isloadingDetailLogs" :showHeader="false" />
            </b-tab>

            <!-- Tab: Transfers -->
            <b-tab v-if="detailService.Transportaciones && detailService.Transportaciones.length > 0">
              <template #title>
                <span style="font-size:14px;">🚐</span>
                <span class="d-none d-sm-inline">Transportaciones</span>
              </template>
              <TableLogs :products="detailService.Transportaciones" :fields="itineraryFields" :isLoadingLogs="isloadingDetailLogs" :showHeader="false" />
            </b-tab>

            <!-- Tab: Bookings -->
            <b-tab v-if="detailService.Reservaciones && detailService.Reservaciones.length > 0">
              <template #title>
                <span style="font-size:14px;">🏩</span>
                <span class="d-none d-sm-inline">Reservaciones</span>
              </template>
              <TableLogs :products="detailService.Reservaciones" :fields="bookingFields" :isLoadingLogs="isloadingDetailLogs" :showHeader="false" />
            </b-tab>

            <!-- Tab: Gusts -->
            <b-tab v-if="detailService.Huespedes && detailService.Huespedes.length > 0">
              <template #title>
                <span style="font-size:14px;">🧳</span>
                <span class="d-none d-sm-inline">Huéspedes</span>
              </template>
              <TableLogs :products="detailService.Huespedes" :fields="guestsFields" :isLoadingLogs="isloadingDetailLogs" :showHeader="false" />
            </b-tab>

            <!-- Tab: Packages -->
            <b-tab v-if="detailService.Paquetes && detailService.Paquetes.length > 0">
              <template #title>
                <span style="font-size:14px;">📦</span>
                <span class="d-none d-sm-inline">Paquetes</span>
              </template>
              <TableLogs :products="detailService.Paquetes" :fields="productFields" :isLoadingLogs="isloadingDetailLogs" :showHeader="false" />
            </b-tab>

            <!-- Tab: Events -->
            <b-tab v-if="detailService.Eventos && detailService.Eventos.length > 0">
              <template #title>
                <span style="font-size:14px;">🎉</span>
                <span class="d-none d-sm-inline">Eventos</span>
              </template>
              <TableLogs :products="detailService.Eventos" :fields="productFields" :isLoadingLogs="isloadingDetailLogs" :showHeader="false" />
            </b-tab>

          </b-tabs>

          <b-alert variant="info" :show="true" class="mb-0" v-else-if="isloadingDetailLogs && detailServiceLength == 0 ">
            <div class="alert-body text-center">
              <p>Cargando Historico de Detalles.</p>
              <b-spinner/>
            </div>
          </b-alert>

          <b-alert variant="warning" :show="true" class="mb-0" v-else-if="(detailServiceLength == 0 && !isloadingDetailLogs)">
            <div class="alert-body text-center">
              Esta orden aún no cuenta con movimientos registrados.
            </div>
          </b-alert>
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"
import { formatModelIntoHistoryLog, getProductName } from '@/helpers/mySalesHelper'
import { productLogsFields, bookingLogsFields, itineraryLogsFields, guestsLogsFields } from "@/data/data"
import flatPickr from "vue-flatpickr-component"
import TableLogs from '@/modules/mySales/components/orderDetail/TableLogs'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  async created(){
    this.isLoadingHistoryLogs = true
    this.loadingLogs({order: true, orderdetail: true})
    this.isLoadingHistoryLogs = false
    // this.setOptions()
  },
  components: {
    flatPickr,
    BCardActions,
    TableLogs
  },
  data(){
    return {
      isLoadingHistoryLogs: false,
      isloadingOrderLogs: false,
      isloadingDetailLogs: false,
      orderService: [],
      detailService: {},
      detailServiceLength: 0,
      rangeDate: null,
      fields: [
        {key: 'sale', label: 'Información de la Venta'},
        {key: 'payment', label: 'Información de Pago'},
        {key: 'logs' , label: 'Registros'}
      ],
      productFields: productLogsFields,
      bookingFields: bookingLogsFields,
      itineraryFields: itineraryLogsFields,
      guestsFields: guestsLogsFields
    }
  },

  computed:{
    ...mapState('auth',['user']),
    ...mapState('mySales', ['selectedOrder', 'orderDetail']),
    ...mapGetters('mySales',['orderDetailProducts','orderDetailTranfers','orderDetailBookings','orderDetailEvents','orderDetailPackages']),
    colSize(){
      return this.detailServiceLength > 1 ? '6' : '12'
    },
  },
  methods: {
    ...mapActions('mySales', ['fetchHistoryLogs']),
    async loadingLogs(requested){
      const {order, orderdetail} = requested
      if (order) this.isloadingOrderLogs = true
      if (orderdetail) this.isloadingDetailLogs = true
      const detail = structuredClone(this.selectedOrder.detail)
      let logsorden = []
      let logsdetail = []

      if (order) {
        const response = await this.fetchHistoryLogs({logidientifier: this.selectedOrder.id, logobject: 'orders'})
        if (response?.length > 0) logsorden.push({details: response, typeObject: 'Orders'})
      }

      if (orderdetail) for (let index = 0; index < detail.length; index++) {
        let typeObject

        if (detail[index]?.booking?.id) {
          const response = await this.fetchHistoryLogs({logidientifier: detail[index].booking?.id, logobject: 'orderbookings'})
          typeObject = 'booking'
          if (response?.length > 0)logsdetail.push({details: response, typeObject})
        }
        if (detail[index]?.booking?.guests?.length) {
          const guests = detail[index]?.booking?.guests
          for (let idx = 0; idx < guests?.length; idx++) {
            const response = await this.fetchHistoryLogs({logidientifier: guests[idx]?.id, logobject: 'multibeneficiaries'})
            typeObject = 'guests'
            if (response?.length > 0)logsdetail.push({details: response, typeObject})
          }
        }
        if(detail[index]?.itinerary?.id) {
          const response = await this.fetchHistoryLogs({logidientifier: detail[index].itinerary?.id, logobject: 'orderitinerary'})
          typeObject = 'itinerary'
          if (response?.length > 0)logsdetail.push({details: response, typeObject})
        }
        if(detail[index]?.id) {
          const response = await this.fetchHistoryLogs({logidientifier: detail[index]?.id, logobject: 'ordersdetail'})
          typeObject = detail[index]?.event ? 'evento' : detail[index]?.package ? 'paquete' : 'producto'
          if (response?.length > 0)logsdetail.push({details: response, typeObject})
        }
      }

      if (logsorden?.length > 0) {
        this.orderService = []
        logsorden.forEach(item => {
          const itemsold = null
          const detailItem = formatModelIntoHistoryLog(item).reverse()
          this.orderService.push({itemsold, detailItem})
        });
        this.isloadingOrderLogs = false
      } else this.isloadingOrderLogs = false

      if (logsdetail?.length > 0) {
        let createOption = false
        const newData = {
          product: [],
          event: [],
          paquete: [],
          booking: [],
          guests: [],
          itinerary: []
        }

        logsdetail.forEach(item => {
          // const username = item.user ? `${item.user.name || ''} ${item.user.lastname || ''}` : null

          const itemsold = getProductName(item) || this.selectedOrder?.detail?.find(detail => detail.id == item.details[0].id)?.itemsold
          const detailItem = formatModelIntoHistoryLog(item).reverse()

          if (item.typeObject == 'evento') newData.event.push({itemsold, detailItem})
          else if (item.typeObject == 'paquete') newData.paquete.push({itemsold, detailItem})
          else if (item.typeObject == 'booking') newData.booking.push({itemsold, detailItem})
          else if (item.typeObject == 'guests') newData.guests.push({itemsold, detailItem})
          else if (item.typeObject == 'itinerary') newData.itinerary.push({itemsold, detailItem})
          else if (item.typeObject == 'producto') newData.product.push({itemsold, detailItem})

          createOption = true
        })
        if (createOption) this.setOptions(newData)
        this.isloadingDetailLogs = false
      } else this.isloadingDetailLogs = false
    },
    setOptions(newData){
      this.detailServiceLength = 0
      this.detailService = {}

      if (newData?.product?.length) {
        this.detailService.Servicios = newData.product
        this.detailServiceLength++
      }
      if (newData?.itinerary?.length) {
        this.detailService.Transportaciones = newData.itinerary
        this.detailServiceLength++
      }
      if (newData?.booking?.length) {
        this.detailService.Reservaciones = newData.booking
        this.detailServiceLength++
      }
      if (newData?.guests?.length) {
        this.detailService.Huespedes = newData.guests
        this.detailServiceLength++
      }
      if (newData?.paquete?.length) {
        this.detailService.Paquetes = newData.paquete
        this.detailServiceLength++
      }
      if (newData?.event?.length) {
        this.detailService.Eventos = newData.event
        this.detailServiceLength++
      }
    },
    async refreshCard(cardName){
      if (cardName.match('order')) this.isloadingOrderLogs = true
      else if (cardName.match('detaillog')) this.isloadingDetailLogs = true
      await this.loadingLogs({order: this.isloadingOrderLogs, orderdetail: this.isloadingDetailLogs})
      this.$refs[cardName].showLoading = false
      this.isloadingOrderLogs = false
      this.isloadingDetailLogs = false
    }
  },
}
</script>

<style>

</style>