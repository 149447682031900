<template>
  <div>

    <div v-if="room.promoCodeManual != ''">
      <div>
      <strong>Tarifa inicial  :</strong>
      <b-badge variant="warning">
        <strong> ${{ bookingSelected.rateInit }} </strong>
      </b-badge>
    </div>
    <div >
      <strong>Tarifa con promo:</strong>
      <strong style="font-size: 12px"> ${{ room.priceInit }} </strong>
    </div>
  </div>
  <div v-else>
  <div >
      <strong>Tarifa inicial:</strong>
       <b-badge variant="warning">
      <strong style="font-size: 12px"> ${{ room.priceInit }} </strong>
       </b-badge>
    </div>
  </div> 
    <div v-if="room.detailSelected.PromotionName != ''">
      <strong>Promoción: </strong>{{ room.detailSelected.PromotionName }}
    </div>
    <div class="mb-1">
      <strong>Total: </strong>$ {{ room.detailSelected.AmountAfterTax }}
    </div>
    <b-row>
      <b-col>
        <!-- {{room.detailSelected.divisa.value}} -->
        <b-form-group :label="'Divisa: ' + room.detailSelected.divisa.code">
          <b-form-select v-model="room.detailSelected.divisa">
            <option v-for="cur in currencies" :key="cur.id" :value="cur">
              {{ cur.code }}
            </option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <!-- {{room.detailSelected.PromotionCode}} -->
        <b-form-group label="Descuento" v-if="!isLoadingRates">
          <b-form-select
            v-model="room.detailSelected.PromotionCode"
            @change="checkPriceMatchByPromotionCode"
          >
            <option value="">Seleccione descuento</option>
            <option
              v-for="promoCode in promoCodes"
              :key="promoCode.id"
              :value="promoCode.name"
            >
              {{ promoCode.name }}
            </option>
          </b-form-select>
        </b-form-group>
        <div class="text-center" v-if="isLoadingRates">
          <b-spinner label="Loading..." variant="success" /><br />
          <strong>Buscando tarifa</strong>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-badge variant="success">
          <feather-icon icon="DollarSignIcon" class="mr-25" />
          <span>{{ precioConversion }} </span>
        </b-badge>
      </b-col>
      <b-col>
        <div class="float-right" v-if="room.rates.length">
          <b-button
            variant="primary"
            @click="updateBooking"
            :disabled="!room.showingRates"
          >
            <feather-icon icon="SaveIcon" size="16" /> Actualizar
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { toDecimal, toJson } from "@/helpers/helpers";

export default {
  props: {
    room: {
      type: Object,
      required: true,
    },
    isLoadingRates: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLoadingRatesPriceMatch: false,
    };
  },
  computed: {
    ...mapState("start", ["promoCodes", "currencies"]),
    ...mapState("mySales", ["bookingSelected", "searchForm"]),
    ...mapState("auth", ["user"]),

    precioConversion() {
      const precioToUse =
        this.room.detailSelected.priceMatchTax != ""
          ? this.room.detailSelected.priceMatchTax
          : this.room.detailSelected.AmountAfterTax;
      const conversion = precioToUse * this.room.detailSelected.divisa.value;
      return toDecimal(conversion);
    },
  },
  methods: {
    ...mapMutations("mySales", ["setRoomsBookingSelected"]),
    checkPriceMatchByPromotionCode(promoCode) {
      const { hoteldinguscode, language } = this.bookingSelected;
      const { dateIn, dateOut, RoomTypeCode } = this.room;
      const { ninos, adults, children } = this.searchForm;

      let arrayNinios = [];
      if (ninos && ninos.length > 0) {
        ninos.forEach((edad) => {
          arrayNinios.push(edad);
        });
      }

      if (promoCode) {
        this.room.detailSelected.allowPriceMatch = false;
        const payload = {
          adults,
          children,
          dateIn,
          dateOut,
          hotel: hoteldinguscode,
          idioma: language,
          isManualRate: false,
          keyRoom: this.room.keyRoom, //lo ocupo de manera interna
          manualRate: "", //lo ocupo de manera interna
          ninos: toJson(arrayNinios),
          priceInit: this.room.detailSelected.priceInit, //lo ocupo de manera interna
          promotionCode: promoCode,
          rateplanid: "",
          roomCode: RoomTypeCode,
          tarifa: this.room?.detailSelected?.RatePlanCode || "", // Es requerido,,
          vendorcode: this.user.canalventa.dingusvendorcode || "",
        };
        // console.log(payload);
        this.$emit("check-price-match-by-promotion-code", payload);
      }
      if (!promoCode) {
        this.room.detailSelected.allowPriceMatch = true;
        if (
          this.room.detailSelected.AmountAfterTax !=
          this.room.detailSelected.priceInit
        ) {
          this.$emit("price-match-initial");
        }
      }
    },
    updateBooking() {
      const payload = {
        authorizationUser: "",
        dateAuthorization: null,
        isAuthorization: false,
        isRateLimit: false,
        urlAuthorization: "",
      };
      this.$emit("update-booking", payload);
    },
  },
};
</script>
